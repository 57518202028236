import { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";
import { media } from "../../lib/utils";
import { generateLocalizedPath } from "../../lib/languages";
import i18n from "../../i18n/config";

const News = () => {
  const { t } = useTranslation();

  const lang = i18n.language;

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setNews([]);
    (async () => {
      try {
        const res = await axios.get(`https://ndmr.co.jp/blog/?rest_route=/wp/v2/posts&_embed&per_page=3&page=1`);
        setNews(res.data);
        setLoading(false);
      } catch (error) {
        setNews([]);
      }
    })()
  }, []);
  return (
    <section>
      <Content>
        <Title>{t("top.news.title")}</Title>
        {loading && <p>Loading...</p>}
        {!loading && news &&(
           <Articles>
            {news.map((article, i) => (
              <Link to={generateLocalizedPath(`/news/${article.id}`, lang)} key={i}>
                <Article>
                  <New />
                  <Date>{dayjs(article.date).format("YYYY/MM/DD")}</Date>
                  <MobileDate>{dayjs(article.date).format("MM/DD")}</MobileDate>
                  <ArtTitle className="two-line">{article["title"]["rendered"]}</ArtTitle>
                  <Image src={article["_embedded"]["wp:featuredmedia"]?.[0]["source_url"]} alt={article["title"]["rendered"]} />
                </Article>
              </Link>
            ))}
          </Articles>
        )}
        <Link to={generateLocalizedPath("/news", lang)}>
          <Button>{t("top.foreigner.button")}</Button>
        </Link>
      </Content>
    </section>
  )
};
export default News;

const Title = styled.h2`
  color: #000;
  font-size: 4rem;
  font-weight: 700;
  margin: 0;

  ${media.lessThan.lg`
    font-size: 3rem;
  `}

  ${media.lessThan.md`
    font-size: 1.5rem;
    text-align: center;
  `}
`;

const Articles = styled.div`
  padding-top: 20px;
`;

const Article = styled.div`
  color: #000;
  margin-bottom: 20px;
  display: flex;
  height: 122px;
  padding: 20px 40px;
  border: 1px solid #7FC08B;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 0.5;
  }

  ${media.lessThan.md`
    padding: 20px 12px;
    height: 90px;
  `}
`;
const Image = styled.div`
  width: 100px;
  height: 80px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;

  ${media.lessThan.md`
    display: none;
    height: 48px;
  `}
`;

const New = styled.div`
  padding: 8px 20px;
  border: 1px solid #7FC08B;
  border-radius: 5px;
  &:before {
    content: "NEW";
    color: #7FC08B;
    font-size: 1.5rem;
    font-weight: 700;
  }

  ${media.lessThan.md`
    display: none;
  `}
`;

const Date = styled.div`
  font-size: 1.5rem;
  padding: 0 20px;

  ${media.lessThan.md`
    display: none;
  `}
`;

const MobileDate = styled.div`
  display: none;
  font-size: 1rem;
  padding: 0 12px;

  ${media.lessThan.md`
    display: block;
  `}
`;

const ArtTitle = styled.div`
  font-size: 1.5rem;
  width: 50%;
  font-weight: 700;

  ${media.lessThan.md`
    font-size: 1rem;
    width: 100%;
  `}
`;

const Button = styled.button`
  width: 100%;
  padding: 20px 40px;
  border-radius: 8px;
  border: 4px solid #7FC08B;

  color: #7FC08B;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 700;
`;
