import styled from "styled-components";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { Content } from "../components/Layouts";
import PageTop from "../components/news/PageTop";
import axios from "axios";
import { media } from "../lib/utils";

const Article = (props) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    setLoading(true);
    setArticle(null);
    (async () => {
      try {
        const res = await axios.get(`https://ndmr.co.jp/blog/?rest_route=/wp/v2/posts/${id}&_embed`);
        setArticle(res.data);
        setLoading(false);
      } catch (error) {
        setArticle(null);
      }
    })()
  }, [id]);
  return (
    <>
      <PageTop />
      <Content>
        {loading && <Spinner animation="border" />}
        {article && (
          <>
            <Img src={article["_embedded"]?.["wp:featuredmedia"]?.[0]["source_url"]} alt={article.title} />
            <Title>{article["title"]?.["rendered"]}</Title>
            <Author>Written by {article["_embedded"]?.["author"]?.[0]["name"]}</Author>
            <ArtCont dangerouslySetInnerHTML={{ __html: article["content"]?.["rendered"] }} />
          </>
        )}
      </Content>
    </>
  )
};

export default Article;

const Img = styled.img`
  display: block;
  width: 50%;
  margin: 0 auto 12px;

  ${media.lessThan.md`
    width: 100%;
  `}
`;

const Title = styled.h1`
  margin-bottom: 12px;
  font-size: 4rem;
  font-weight: 700;

  ${media.lessThan.lg`
    font-size: 2rem;
  `}
`;

const Author = styled.p`
  margin-bottom: 12px;
  font-size: 1.25rem;
`;

const ArtCont = styled.div`
  margin-bottom: 100px;
  font-size: 1rem;

`;
