import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { generateLocalizedPath } from "../lib/languages";
import i18n from "../i18n/config";

import { Content } from "./Layouts";
import LanguageSelector from "./LanguageSelector";

import { media } from "../lib/utils";

const Header = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const links = [{
    nameKey: "header.top",
    path: "/",
  }, {
    nameKey: "header.real_estate",
    path: "/business/real-estate",
  }, {
    nameKey: "header.career_consulting",
    path: "/business/career-consulting",
  }, {
    nameKey: "header.ceo_message",
    path: "/ceo-message",
  }, {
    nameKey: "header.recruit",
    path: "/recruit",
  }];
  const language = i18n.language;

  return (
    <HeaderWrapper open={open}>
      <Hamburger open={open} onClick={() => setOpen(!open)} />
      <Content>
        <Links open={open}>
          {links.map((link, i) => (
            <Link key={i} to={generateLocalizedPath(link.path, language)} onClick={() => setOpen(false)}>
              <LinkName>{t(link.nameKey)}</LinkName>
            </Link>
          ))}
        </Links>
        <LanguageSelector current={language} />
      </Content>
    </HeaderWrapper>
  )
};
export default Header;

const Hamburger = styled.div`
  display: none;
  position: fixed;
  left: 20px;
  top: 38px;
  width: 40px;
  height: 4px;
  background: ${props => props.open ? "transparent" : "#000000"};
  border-radius: 2px;
  transition: all 0.3s ease;

  &::before, &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 40px;
    height: 4px;
    background: #000000;
    border-radius: 2px;
    transition: all 0.3s ease;
  }
  &::before {
    top: ${props => props.open ? "0" : "-10px"};
    transform: ${props => props.open ? "rotate(45deg)" : "none"};
  }
  &::after {
    top: ${props => props.open ? "0" : "10px"};
    transform: ${props => props.open ? "rotate(-45deg)" : "none"};
  }


  ${media.lessThan.lg`
    display: block;
  `}
`;

const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 99;
  background: #FFFFFF66;
  padding: 12px 0;
  width: 100%;
  max-width: ${props => props.theme.page.width};
  top: 0;

  line-height: 4rem;
  transition: all 0.3s ease;

  ${media.lessThan.lg`
    background: ${props => props.open ? "#FFFFFFC0" : "transparent"};
    height: ${props => props.open ? "100vh" : "auto"};
  `}
`;

const Links = styled.div`
  display: inline-block;
  align-items: center;

  ${media.lessThan.lg`
    display: ${props => props.open ? "block" : "none"};
    padding-left: 60px;
    margin-top: 64px;
  `}
`;

const LinkName = styled.span`
  display: inline-block;
  padding: 0 15px;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 38px;
  text-decoration: none;
  border-radius: 5px;

  transition: all 0.3s ease;

  &:hover {
    color: #00000066;
    background: #FFFFFF66;
  }

  ${media.lessThan.lg`
    display: block;
    padding: 20px;
  `}
`;