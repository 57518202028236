import NewsItems from "../components/news/NewsItems";
import PageTop from "../components/news/PageTop";

const News = () => {
  return (
    <>
      <PageTop />
      <NewsItems />
    </>
  )
};

export default News;
