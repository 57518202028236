const translation_zhCN = {
  title: "Nodomaru Co.,Ltd",
  header: {
    top: "TOP",
    real_estate: "REAL ESTATE",
    career_consulting: "CAREER CONSULTING",
    recruit: "RECRUIT",
    ceo_message: "CEO MESSAGE",
  },
  top: {
    title: "享受清静慢活的人生",
    business: {
      title: "Business",
      subtitle: "事业内容",
      desc: "为了实现清静慢活的和平社会，本公司不仅在日本国内，还在海外开展业务，以帮助每个人过上和平生活的职业咨询业务和帮助实现和平生活的房地产业务为中心，不断扩大公司规模。",
      real_estate: {
        title: "房地产",
        subtitle: "REAL ESTATE",
      },
      career_consulting: {
        title: "职业规划咨询",
        subtitle: "CAREER CONSULTING",
      },
    },
    staff: {
      title: "工作人员博客/社交媒体",
      subtitle: "关于公司",
      desc: "公司名称“Nodomaru”是一个古老的词，意思是“和平清静”。<br />我们将尽量帮助尽更多的客户实现“悠闲生活”。",
      links: {
        instagram: "IG",
        blog: "工作人员博客",
        tiktok: "抖音",
        facebook: "脸书",
      },
    },
    slogan: {
      title: "享受清静慢活的人生",
      mission: {
        title: "任务目标",
        desc: "和有缘人一起走向和平清静的人生",
      },
      vision: {
        title: "未来愿景",
        desc: "通过公司事业項目创建和平的永续发展社会",
      },
      value: {
        title: "VALUE",
        desc: "为“精神幸福和经济独立”做贡献，利用企业人的优势， 培养自己成为一个既具有体贴他人的“善意”，又具有真正意义上保护他人的“力量”的人。<br /><br/>拥有坚定而柔软的信念。让周围的人幸福并且平静地生活。",
      },
    },
    foreigner: {
      title: "让您轻而易举地在日本找到理想的住家。",
      desc: "提供英语的语言服务<br /><b>如果您從海外移居到日本，请点击此处</b>",
      button: "了解更多",
    },
    news: {
      title: "新闻及媒体报道",
      button: "查看更多",
    },
    contact: {
      title: "询问",
      tel: "公司电话",
      email: "查询电子邮件地址",
      form: {
        name: "名字",
        com_name: "公司名称",
        email: "电子邮件地址",
        email_confirm: "电子邮件地址（确认用）",
        tel: "电话号码",
        message: "查询详情",
        submit: "提交",
        memo: "*请填写必须项目",
      },
    },
    company: {
      title: "公司信息",
      name: "名称 - Nodomaru Co., Ltd.",
      established: "成立日期 - 2019年1月18日",
      representative: "董事长 - 向仲 骏",
      license1: "不动产交易事业执照号码 - 东京都知事第104535号",
      license2: "有偿就业安置事业执照号码 - 13-ユ(Yu)-313195",
      address: "地址 - 东京都中央区八丁堀4-11-4 八丁堀前2楼",
      tel: "电话号码 - 03-6456-4538",
      fax: "传真号码 - 03-6456-4537",
      business: {
        title: "事业内容",
        real_estate: "房地产业务",
        placement_agency: "人才招聘业务",
      },
    },
  },
  real_estate: {
    title: "房地产",
    subtitle: "REAL ESTATE",
    rental: {
      title: "房屋租赁事业部",
      subtitle: "REAL ESTATE RENTAL SERVICE",
      desc: "在我们的房地产租赁业务服务中，我们提供并建议最适合客户需求的房产。 我们服务于从个人到企业的广泛客户，并经营各种物业，包括出租公寓、商店和办公楼。 此外，近年来，随着全球化的发展，越来越多的企业积极接纳外国人，通过与企业的合作，我们还致力于为外国人提供租赁服务之项目。",
    },
    sales: {
      title: "房地产买卖事业部",
      subtitle: "REAL ESTATE SALES",
      desc: "我们的房地产买卖业务提供有关独栋住宅、公寓、商业房地产等买卖的广泛咨询。 我们向海外投资者出售资产价值较高的公寓，我们经验丰富、会说英语的员工将协助您办理房产交易相关手续以及税务和法律方面的咨询，让您安心进行交易，无后顾之忧。",
    },
    management: {
      title: "房地产管理事业部",
      subtitle: "REAL ESTATE SALES",
      desc: "我们的房地产管理业务旨在实现业主资产价值最优化。 通过以中央区、江东区为中心的公寓分割管理以及整栋大楼的管理，全力支持业主的资产管理。 我们还管理大量海外业主拥有的房产，并根据国内市场的趋势提出适当的管理建议。",
    },
    services: {
      title: "房屋租赁服务",
      item1: {
        title: "扎根于本地的信息收集能力",
        desc: "我们拥有丰富的当地信息，擅长寻找符合客戶需求的房产，并熟悉最新的市场趋势。",
      },
      item2: {
        title: "为每位客户提供量身定制的专业服务",
        desc: "由于该地区有很多海外归来或从远方调职而来的客户，我们也积极提供在线看房服务。",
      },
      item3: {
        title: "通过推荐介绍和回头客建立了良好的信任关系及口碑",
        desc: "在我们公司，大約约70% 的合同是回头客以及介绍之客戶。 我将继续通过提供高质量的服务來提高客戶满意度，同时重視人际关系难得的缘分。",
      },
      item4: {
        title: "通过多种语言為广泛的客戶提供相关服务",
        button: "如果您来自海外，请点击此处",
        desc: "本公司雇佣会说英语、汉语、韩语的员工，能够为想要在日本生活的外国客户提供各种服务。 使我们能够超越国家和语言障碍，满足广泛客户的需求。",
      },
      item5: {
        title: "通过企业合作伙伴关系为员工提供住房服务",
        desc: "我们与东京的许多公司建立了企业合作伙伴关系，并为其员工提供全面的住房服务。 在合同问题频发的租赁行业中，我们提供值得您放心信赖的专属服务。",
      },
    },
  },
  career_consulting: {
    title: "职业规划咨询",
    subtitle: "CAREER CONSULTING",
    job_change: {
      title: "跳槽就业咨询服务",
      subtitle: "JOB CHANGE SUPPORT",
      desc: "工作时间占据了人生生活的大部分。 为了让工作成为自我实现的场所，我们将从大公司到初创公司的大量职位空缺中向您介绍符合您希望和能力的工作。",
    },
    consulting: {
      title: "招聘咨询",
      desc: "在人、物、财的管理资源中，“人”是构成企业基础的最重要的资源。 为了解决贵公司的人力资源招聘以及是否能长期稳定工作的问题，我们引进20多岁、30多岁的年轻人力资源来加入贵公司的团队。",
    },
    matching: {
      title: "公司求职者匹配重点",
      desc: "着眼长远，实现招聘公司与求职者的最佳匹配。 我们不仅根据匹配条件来考虑候选人，还考虑公司的文化和适合性，以确保双方的长期成功。",
    },
    services: {
      title: "Career Consulting Services",
      item1: {
        title: "专门针对20多岁、30多岁的年轻人",
        desc: "工作时间占据了你生活的很大一部分。 对于20多岁、30多岁充满潜力的人，我们希望他们通过工作来拓展自己的潜力，所以我们全力支持他们跳槽，并且一直到找到理想的公司为止提供完整服务，无论他们的教育背景或工作经历如何。 因为我们提供专门针对20多岁、30多岁的人跳槽服务，所以我们熟悉年轻人的跳槽情况。",
      },
      item2: {
        title: "根据职业顾问所制定的中长期职业规划",
        desc: "我们寻求“哪种职业选择最适合您，包括跳槽以外的可能性。 我们的会员均为国家认证职业顾问，将为您精心规划职业生涯，实现长期职业发展。 我们还定期举办自我分析研讨会和如何思考换工作的研讨会。",
      },
      item3: {
        title: "来自人力资源行业经验丰富的人员的全面支持服务",
        desc: "我们公司的另一个特点是，我们的许多员工都曾在各大人力资源公司工作过，深谙职场现况。 我们经验丰富的职业顾问能提供广泛的一条龙服务，从文件修改到面试准备。 我们为您的求职提供全面支持，同时提供瞬息万变的就业市场、行业、职位类型等信息！",
      },
      item4: {
        title: "各类型企业的介绍推荐",
        desc: "我们将介绍符合您人生规划的公司，从大公司到初创公司，营业销售到办公室专职等职业。 我们将为您提供通过自我申请无法获得的信息，以及招聘信息中未包含的真实公司信息，例如工作场所氛围文化等。",
      },
      item5: {
        title: "转职跳槽后的专业职业辅导",
        desc: "转职跳槽不是最终目标。 作为生活指导者，我们通过将工作变动视为一条线而不是一个点来支持您的生活，并通过工作来丰富您的生活。 即使换工作后，合格的专业指导者也会陪伴您进行辅导，帮助您实现理想的人生蓝图。",
      },
    },
  },
  ceo_message: {
    title: "董事长致辞及成员介绍",
    subtitle: "CEO Message and Members",
    message: {
      title: "CEO MESSAGE",
      ceo_name: "向仲 駿",
      content: "愿有缘人过上清静平稳的生活。感谢您访问Nodomaru的主页。<br /><br/>大家知道“Nodomaru”的意思吗？<br /><br />Nodomaru是一个古老的日语单词，意思是“平和”。<br /><br/>本公司成立于2019年，以“为有缘人提供安宁生活”为公司使命，一直到发展房地产和人力资源招聘业务。<br /><br/>在当今多元化的时代，各种思维方式、生活方式不断呈现，我感到正义邪恶交织在一起。<br /><br/>如果您在互联网上搜索，您很快就会找到“一个正确答案”。<br /><br/>然而，由于信息太多，我觉得找到最适合你的信息变得越来越困难。<br /><br/>通过与每个人的彻底对话，我们为与我们有缘分的人提供真正必要的信息和支持。<br /><br/>生活和工作对于过上平静的生活至关重要。<br /><br/>通过这些业务，我们的目标是为我们的员工、客户和业务合作伙伴创造一个安宁的社会。<br /><br/>Nodomaru 的目标是通过重视人的情感和缘分，成为一家自然而温暖的公司，并以真正的 Nodomaru 方式为世界做出贡献。<br /><br/>",
    },
    members: {
      member1: {
        name: "片山慎梧",
        position: "租赁中介部门",
        history: "曾在一家大型招聘机构担任招聘顾问，后来在一家房地产新创公司设立了销售部门。 目前从事租赁中介部门的销售和新员工的培训工作。",
        private: "最喜欢的食物是豆腐",
      },
      member2: {
        name: "上野ひより(Hiyori)",
        position: "租赁中介部门",
        history: "大学期间曾到加拿大留学学习英语。 目前，她正在利用这些经验来支持与国际海外相关的运营工作。对美容情报方面很感兴趣，也经常上传有关房地产和美容信息到各个社交媒体上。",
        private: "各种各样化妆品的收集者",
      },
      member3: {
        name: "广岛 明奈",
        position: "总经理",
        history: "旅外一阵子后，在一家外资保险公司获得了5年的销售经验。之后，成为一名健康管理指导者，一直以来支持着很多人的美丽和健康。 根据本身各种经验，目前活跃于会计、总务、人力资源和销售等领域。",
        private: "喜欢自然和美丽的事物",
      },
      member4: {
        name: "見上のどか(Nodoka)",
        position: "人力资源招聘部",
        history: "曾在一家大型招聘机构担任招聘顾问，目前负责人力资源部门的组建。 2022年，获得了国家职业顾问资格，也为20多岁、30多岁的人提供职业咨询服务。",
        private: "喜欢山胜过一日三餐",
      },
    },

  },
  recruit: {
    title: "人员招募",
    subtitle: "RECRUITING",
    members: {
      title: "团队成员",
      subtitle: "MEMBER COMMENT",
      desc: "我们每天都在为 \"和有缘人一起走向和平清静的人生\"而努力。我们正在寻找认同我们的愿景和使命，并能共同迎接挑战的人。",
      member_descs: {
        history: "迄今为止的背景工作，例如",
        reason: "选择 NODOMARU 的原因。",
        job: "您目前的职责是什么？",
        gap: "在您加入公司之前和之后，是否存在差距？",
        who: "你想与什么样的人共事？",
        goal: "您未来的目标是什么？",
        open_button: "访谈",
        close_button: "关闭",
      },
      member1: {
        name: "見上のどか(Nodoka)",
        position: "人事招聘部",
        history: "在一家大型人力资源公司工作了两年，负责向企业推荐人力资源广告的销售工作。",
        interview: {
          reason: "我对“创建自己公司”的未来感到兴奋，而不是加入一家已经创建的公司。 当我刚决定加入公司时，公司只有三名成员，一切都还未成形，所以周围很多人反对，我有点儿焦虑，但我相信能够和这些人创造出令人兴奋的成果和未来。我有强烈的愿望这样做，所以我相信自己的直觉并决定加入这家公司。",
          job: "我参与外部各方的招聘、公共关系和职业咨询等工作。 人力资源和职业咨询都与工作有关，但我觉得这是一份有意义的工作，可以让我参与到人们通过工作改变的时刻。",
          gap: "比我想象的更自由。 我有一个百分百支持我做任何我想做的事情的环境，但因为我有如此多的自由，我也感到有责任履行我的角色。 公司的气氛总体上平稳而温暖，但我也觉得自己能够以良好的紧张感来履行自己的职责，因为我被赋予了责任。",
          who: "我认同Nodomaru的“精神幸福和经济独立”的价值观，并认为为了让周围的人幸福，你需要做人的善良和做生意的力量。这对于那些有同感的人来说这家公司是个完美的环境。",
          goal: "今后，我也想继续以人力资源官的身份参与招聘和人力资源开发工作，培养能够在世界上发挥积极作用的人才。",
        },
      },
      member2: {
        name: "上野ひより(Hiyori)",
        position: "租赁中介部",
        history: "大学期间，对出国留学很感兴趣，所以在必修课程中学习了英语和韩语。",
        interview: {
          reason: "因为我想在Nodomaru尝试一下销售工作。 当我第一次来到Nodomaru时，我很惊讶地发现这是一家充满笑声的公司。 从找工作开始，我对工作只有负面的印象，对成为一名工作成年人感到非常沮丧，但我第一次对一家公司感到兴奋和期待，认为我可以在Nodomaru享受有趣的工作生活。 最初我听说公司没有招聘应届毕业生的计划，但仍然给了我实习的机会，所以我迫切希望加入公司。",
          job: "我在租赁中介部门工作。 在协助找房的过程中认识了很多人，很开心。 而且，当有人感谢我将这份工作委托给我的那一刻，我真的很高兴我选择了销售职业。 我原本很害羞，不太喜欢与陌生人交流，所以我对销售工作感到担心，但我喜欢在Nodomaru工作，这里让我慢慢儿成长。",
          gap: "没有特别的差距隔阂。 我有过一个月的实习经历，所以在进公司之前就有很多和Nodomaru互动的机会，而且每个人在进公司之前和之后都是这样的表里如一。",
          who: "我是一个喜欢刺激、渴望个人成长的人。 Nodomaru 拥有支持个人成长的环境。 我想享受将个人成长与公司成长联系在一起的兴奋感。",
          goal: "我想在海外白手起家创办一家公司。 为此，我将继续磨练我的业务技能。",
        },
      },
      member3: {
        name: "村上潤",
        position: "租赁管理部",
        history: "先在一家钢铁贸易公司工作了三年，之后为一家大型电子制造商从事销售工作。",
        interview: {
          reason: "跳槽的原因是因为我认为这是一个让我可以像以前一样充满活力工作的环境。 在我之前的工作中，我有一种强烈的感觉，我是为了工作而活，在那里工作让我感到不舒服。 有点类似于工作日潛入水中不能呼吸，周末才能休息一下的窒息感。 当时我刚好有机会能与向仲总裁你交谈，我觉得NODOMARU应该能够消除这种不舒服的感觉，于是我义无反顾地决定加入这家公司。这是一个可以让我充分发挥自己的潛力，取得成果的环境。",
          job: "我们正在努力扩大业务，同时也在租赁管理部门提供现场支持服务。 同时创建和经营一家企业有时会很困难，但我发现從概念到执行所有事情都可以自行决定是一件很有趣的事情。",
          gap: "没有特别的差距隔阂。 它没有经过任何装饰，所以给人的感觉就是它本来的样子。",
          who: "一个永远不会放弃自己思考的人。 只要我们不放弃，前进的路就永远存在，并且Nodomaru提供一个每个人都跟进的系统。",
          goal: "我的目标是成为一名高层主管，从更大更高的视野角度来创建Nodomaru。",
        },
      },
    },
    office: {
      title: "Office Environment",
      subtitle: "办公室相簿",
    },
    bottom: {
      title: "加入团队",
      subtitle: "关于公司",
      desc: "公司名称“Nodomaru”是一个古老的词语，意思是“心境变得平稳”。 <br/>我们将尽可能帮助更多的客户实现“清静慢活‘的理想。",
      button: "了解更多信息",
    },
  },
  news: {
    title: "新闻及媒体报道",
    subtitle: "PRESS RELEASE/NEWS",
    categories: "类别",
  },
};
export default translation_zhCN;
