import styled from "styled-components";

import { Content } from "../Layouts";
import { media } from "../../lib/utils";

const BusinessDetailOne = ({ title, subtitle, desc, image }) => {
  return (
    <section>
      <Content>
        <MobileTitle>{title}</MobileTitle>
        <MobileSubtitle>{subtitle}</MobileSubtitle>
        <Img src={image} alt={title} />
        <div className="flex mb60">
          <div>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </div>
          <Desc>{desc}</Desc>
        </div>
      </Content>
    </section>
  )
};
export default BusinessDetailOne;

const MobileTitle = styled.h2`
  display: none;
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 0;

  ${media.lessThan.md`
    display: block;
  `}
`;

const MobileSubtitle = styled.h3`
  display: none;
  color: #000;
  font-size: 1.25rem;
  text-align: center;
  margin: 0 0 12px;

  ${media.lessThan.md`
    display: block;
  `}
`;

const Img = styled.img`
  width: 100%;
  border-radius: 10px;
  margin-bottom: 12px;
`;

const Title = styled.h2`
  white-space: nowrap;
  margin: 0 40px 0 0;
  font-size: 48px;
  font-weight: 700;

  ${media.lessThan.md`
    display: none;
  `}
`;

const Subtitle = styled.h3`
  white-space: nowrap;
  margin: 0 40px 0 0;
  font-size: 1.25rem;
  font-weight: 400;

  ${media.lessThan.md`
    display: none;
  `}
`;

const Desc = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
`;
