import { useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";
import { media } from "../../lib/utils";

const Business = () => {
  const { t } = useTranslation();

  const nameRef = useRef(null);
  const companyRef = useRef(null);
  const emailRef = useRef(null);
  const confirmEmailRef = useRef(null);
  const phoneRef = useRef(null);
  const messageRef = useRef(null);
  const inputrefs = [nameRef, companyRef, emailRef, confirmEmailRef, phoneRef, messageRef];

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("your-name", nameRef.current.value);
    formData.append("your-company", companyRef.current.value);
    formData.append("your-email", emailRef.current.value);
    formData.append("your-confirmemail", confirmEmailRef.current.value);
    formData.append("your-phonenumber", phoneRef.current.value);
    formData.append("your-message", messageRef.current.value);
    axios.post("https://ndmr.co.jp/blog/index.php?rest_route=/contact-form-7/v1/contact-forms/39/feedback",formData)
      .then((res) => {
        console.log(res);
        if (res.data.status === "mail_sent") inputrefs.forEach((input) => input.current.value = "");
      });
  };
  return (
    <Section id="contactForm">
      <Content>
        <Box>
          <div>
            <Title>{t("top.contact.title")}</Title>
            <Desc>
              {t("top.contact.tel")} : 03-6456-4538<br />
              {t("top.contact.email")} : info@ndmr.co.jp
            </Desc>
          </div>
          <Form onSubmit={onSubmit}>
            <Input type="text" placeholder={`${t("top.contact.form.name")}*`} required={true} ref={nameRef} />
            <Input type="text" placeholder={t("top.contact.form.com_name")} required={false} ref={companyRef} />
            <Input type="email" placeholder={`${t("top.contact.form.email")}*`} required={true} ref={emailRef} />
            <Input type="email" placeholder={`${t("top.contact.form.email_confirm")}*`} required={true} ref={confirmEmailRef} />
            <Input type="text" placeholder={`${t("top.contact.form.tel")}*`} required={true} ref={phoneRef} />
            <Input type="text" placeholder={t("top.contact.form.message")} required={false} ref={messageRef} />
            <Button type="submit">{t("top.contact.form.submit")}</Button>
            <Memo>{t("top.contact.form.memo")}</Memo>
          </Form>
        </Box>
      </Content>
    </Section>
  )
};
export default Business;

const Section = styled.section`
  margin: 60px 0;
`;

const Box = styled.div`
  display: flex;

  ${media.lessThan.lg`
    flex-direction: column;
  `}
`;

const Title = styled.h2`
  color: #000;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 12px;

  ${media.lessThan.lg`
    text-align: center;
    font-size: 2rem;
  `}
`;

const Desc = styled.p`
  color: #000;
  font-size: 1rem;
  font-weight: 400;

  ${media.lessThan.lg`
    text-align: center;
  `}
`;

const Form = styled.form`
  width: 558px;
  margin-left: auto;
  
  ${media.lessThan.lg`
    width: 100%;
    margin-left: 0;
  `}
`;

const Input = styled.input`
  width: 100%;
  padding: 20px 40px;
  border: 4px solid #B7B7B7;
  border-radius: 8px;
  font-size: 1.5rem;
  margin-bottom: 24px;

  &:focus {
    border: 4px solid #000;
  }

  // number arrow hide
  &:-webkit-inner-spin-button, &:-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }

  ${media.lessThan.md`
    font-size: 1rem;
    padding: 12px 24px;
    margin-bottom: 12px;
  `}
`;

const Button = styled.button`
  width: 100%;
  padding: 20px 40px;
  border: 4px solid #7FC08B;
  border-radius: 8px;
  background: #7FC08B;
  color: #fff;

  font-size: 1.5rem;
  margin-bottom: 8px;

  ${media.lessThan.md`
    font-size: 1rem;
    padding: 12px 24px;
  `}
`;

const Memo = styled.span`
  font-size: 1rem;
`;
