import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";
import { media } from "../../lib/utils";

const Message = () => {
  const { t } = useTranslation();

  return (
    <section>
      <Content>
        <Text>
          <div>
            <h2>{t("ceo_message.message.title")}</h2>
            <h3>{t("ceo_message.message.ceo_name")}</h3>
          </div>
          <p dangerouslySetInnerHTML={{ __html: t("ceo_message.message.content") }} />
        </Text>
      </Content>
    </section>
  )
};
export default Message;

const Text = styled.div`
  display: flex;
  gap: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #000;
  margin-bottom: 50px;

  h2 {
    margin: 0;
    font-size: 3rem;
    font-weight: 700;
    white-space: nowrap;
  }
  h3 {
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    white-space: nowrap;
  }
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  ${media.lessThan.md`
    flex-direction: column;
    h2 {
      font-size: 2rem;
    }
  `}
`;
