import styled from "styled-components";
import { useTranslation } from "react-i18next";

import MatchingImage from "../../../assets/business/career-consulting/matching.png";
import Logo from "../../../assets/logos/white.svg";
// import Logo from "../../../assets/business/career-consulting/logo.png";
import { media } from "../../../lib/utils";

const Consulting = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Image />
      <Desc>
        <h2>{t("career_consulting.matching.title")}</h2>
        <p>{t("career_consulting.matching.desc")}</p>
        <img src={Logo} alt="nodomaru" />
      </Desc>
    </Section>
  )
};
export default Consulting;

const Section = styled.section`
  display: flex;

  ${media.lessThan.lg`
    flex-direction: column;
  `}
`;

const Image = styled.div`
  width: 50%;
  padding-bottom: 37.5%;
  background: url(${MatchingImage});
  background-size: cover;
  background-position: center center;

  ${media.lessThan.lg`
    width: 100%;
    padding-bottom: 70%;
  `}
`;

const Desc = styled.div`
  display: flex;
  width: 50%;
  background: #EDE5DC;
  padding-left: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  h2 {
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
  }
  p {
    max-width: 500px;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
  }
  img {
    width: 84px;
  }

  ${media.lessThan.lg`
    display: block;
    width: 100%;
    padding: 24px;
    h2 {
      text-align: center;
    }
    p {
      max-width: 100%;
      margin: 20px 0;
    }
    text-align: center;
  `}
  ${media.lessThan.md`
    h2 {
      font-size: 2rem;
    }
  `}
`;
