import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";

import ForeignerImage from "../../assets/top/foreigner.png";
import { Link } from "react-router-dom";
import { media } from "../../lib/utils";

const Foreigner = () => {
  const { t } = useTranslation();
  return (
    <section>
      <Content>
        <Box>
          <ImgWrapper />
          <TextWrapper>
            <Bold>{t("top.foreigner.title")}</Bold>
            <span dangerouslySetInnerHTML={{ __html: t("top.foreigner.desc") }} />
            <Link to="/foreigner/lp1"><Button>{t("top.foreigner.button")}</Button></Link>
          </TextWrapper>
        </Box>
      </Content>
    </section>
  )
};
export default Foreigner;

const Box = styled.div`
  display: flex;
  width: 100%;
  padding: 40px;
  justify-content: center;
  align-items: center;
  gap: 48px;
  border-radius: 10px;
  background: #7FC08B;
  margin-bottom: 60px;

  ${media.lessThan.lg`
    flex-direction: column;
  `}

  ${media.lessThan.sm`
    width: calc(100% + ${props => props.theme.content.padding} * 2);
    padding: 0;
    margin: 0 -${props => props.theme.content.padding} 40px;
    border-radius: 0;
  `}
`;

const ImgWrapper = styled.div`
  background: url(${ForeignerImage});
  background-size: cover;
  background-position: center center;
  width: 30%;
  padding-bottom: 25%;
  border-radius: 10px;

  ${media.lessThan.lg`
    width: 100%;
    padding-bottom: 60%;
  `}

  ${media.lessThan.sm`
    border-radius: 0;
  `}
`;

const TextWrapper = styled.div`
  width: 70%;
  height: 25%;
  font-size: 2rem;
  color: #FFF;
  line-height: normal;
  b {
    &:after {
      content: "";
      /* margin-right: 24px; */
    }
  }

  ${media.lessThan.lg`
    width: 100%;
  `}

  ${media.lessThan.sm`
    padding: 0 24px;
    text-align: center;
    margin-bottom: 40px;
    font-size: 1.5rem;
  `}
`;

const Bold = styled.p`
  font-size: 2.5rem;
  font-weight: 700;

  ${media.lessThan.sm`
    font-size: 2rem;
  `}
`;

const Button = styled.button`
  display: block;
  background: transparent;
  margin-top: 20px;
  padding: 20px 40px;
  border-radius: 8px;
  border: 4px solid #FFF;
  color: #FFF;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;

  ${media.lessThan.sm`
    margin: 20px auto 0;
    display: block;
  `}
`;
