import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../../Layouts";
import { Link } from "react-router-dom";
import { media } from "../../../lib/utils";

const Services = () => {
  const { t } = useTranslation();

  return (
    <section>
      <Content>
        <Title>{t("real_estate.services.title")}</Title>
        <UpperDesc>
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: t("real_estate.services.item1.title")}} />
            <p>{t("real_estate.services.item1.desc")}</p>
          </div>
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: t("real_estate.services.item2.title")}} />
            <p>{t("real_estate.services.item2.desc")}</p>
          </div>
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: t("real_estate.services.item3.title")}} />
            <p>{t("real_estate.services.item3.desc")}</p>
          </div>
        </UpperDesc>
        <LowerDesc>
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: t("real_estate.services.item4.title")}} />
            <Link to="/foreigner/lp1">
              <Button>{t("real_estate.services.item4.button")}</Button>
            </Link>
            <p>{t("real_estate.services.item4.desc")}</p>
          </div>
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: t("real_estate.services.item5.title")}} />
            <p>{t("real_estate.services.item5.desc")}</p>
          </div>
        </LowerDesc>
      </Content>
    </section>
  )
};
export default Services;

const Title = styled.h2`
  text-align: center;
  font-size: 4rem;
  font-weight: 400;
  line-height: 9rem;
  margin-bottom: 60px;

  ${media.lessThan.lg`
    font-size: 2rem;
    line-height: normal;
    font-weight: 700;
    padding: 24px 0;
    border-bottom: 1px solid #7FC08B;
  `}
`;

const UpperDesc = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
  div {
    width: 33.333%;
    h3 {
      margin-bottom: 24px;
      br {
        display: none;
      }
    }
    p { margin: 0; }
  }

  ${media.lessThan.lg`
    flex-direction: column;
    div {
      width: 100%;
      h3 {
        text-align: center;
        br {
          display: block;
        }
      }
    }
  `}
`;

const LowerDesc = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 80px;
  div {
    width: 50%;
    h3 {
      margin-bottom: 24px;
      br {
        display: none;
      }
    }
    p { margin: 0; }
  }

  ${media.lessThan.lg`
    flex-direction: column;
    div {
      width: 100%;
      h3 {
        text-align: center;
        br {
          display: block;
        }
      }
    }
  `}
`;

const Button = styled.button`
  font-size: 24px;
  font-weight: 700;
  padding: 10px 40px;
  background: transparent;
  border-radius: 8px;
  border: 4px solid #000;
  margin-bottom: 24px;
`;
