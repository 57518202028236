import { useEffect } from "react";
import styled from "styled-components";
import { getLangFromUrl } from "../lib/languages";
import i18n from "../i18n/config";
import { useLocation } from "react-router-dom";

const Language = ({ children }) => {
  const location = useLocation();
  const lang = getLangFromUrl(location);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <LangStyle lang={lang}>
      {children}
    </LangStyle>
  );
};
export default Language;

const LangStyle = styled.div`
  ${props => {
    switch (props.lang) {
      case "ja":
      case "en":
        return `
          font-family: "Noto Sans JP", sans-serif;
        `;
      case "zh-CN":
        return `
          font-family: "Noto Sans SC", sans-serif;
        `;
      case "zh-TW":
        return `
          font-family: "Noto Sans TC", sans-serif;
        `;
      default:
        return `
          font-family: "Noto Sans JP", sans-serif;
        `;
    }
  }}
`;
