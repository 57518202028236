import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";

import KatayamaImg from "../../assets/ceo-message/members/katayama.png";
import UenoImg from "../../assets/ceo-message/members/ueno.png";
import HiroshimaImg from "../../assets/ceo-message/members/hiroshima.png";
import MikamiImg from "../../assets/ceo-message/members/mikami.png";
import { media } from "../../lib/utils";

const Members = () => {
  const { t } = useTranslation();

  const members = [{
    image: KatayamaImg,
    name: t("ceo_message.members.member1.name"),
    position: t("ceo_message.members.member1.position"),
    history: t("ceo_message.members.member1.history"),
    private: t("ceo_message.members.member1.private"),
  }, {
    image: UenoImg,
    name: t("ceo_message.members.member2.name"),
    position: t("ceo_message.members.member2.position"),
    history: t("ceo_message.members.member2.history"),
    private: t("ceo_message.members.member2.private"),
  }, {
    image: HiroshimaImg,
    name: t("ceo_message.members.member3.name"),
    position: t("ceo_message.members.member3.position"),
    history: t("ceo_message.members.member3.history"),
    private: t("ceo_message.members.member3.private"),
  }, {
    image: MikamiImg,
    name: t("ceo_message.members.member4.name"),
    position: t("ceo_message.members.member4.position"),
    history: t("ceo_message.members.member4.history"),
    private: t("ceo_message.members.member4.private"),
  }];
  return (
    <section>
      <Content>
        <MembersWrapper>
          {members.map((member, i) => (
            <Member key={i}>
              <img src={member.image} alt={member.name} />
              <h2>{member.name}</h2>
              <h3>{member.position}</h3>
              <p>{member.history}</p>
              <p>{member.private}</p>
            </Member>
          ))}
        </MembersWrapper>
      </Content>
    </section>
  )
};
export default Members;

const MembersWrapper = styled.div`
  display: flex;
  padding-top: 30px;
  gap: 30px;

  ${media.lessThan.md`
    flex-direction: column;
  `}
`;

const Member = styled.div`
  width: 25%;
  margin-bottom: 100px;
  img {
    width: 100%;
    border-radius: 10px;
  }
  h2 {
    margin: 10px 0;
    font-size: 2rem;
    font-weight: 700;
  }
  h3 {
    margin: 0 0 10px;
    font-size: 1.25rem;
    font-weight: 400;
  }
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  ${media.lessThan.md`
    width: 100%;
  `}
`;
