import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../../Layouts";

import ManagementImage from "../../../assets/business/real-estate/management.png";
import Logo from "../../../assets/logos/white.svg";
import { media } from "../../../lib/utils";

const Management = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Content>
        <Box>
          <Inner>
            <h2>{t("real_estate.management.title")}</h2>
            <h3>{t("real_estate.management.subtitle")}</h3>
            <p>
              {t("real_estate.management.desc")}
            </p>
            <img src={Logo} alt="nodomaru" />
          </Inner>
        </Box>
      </Content>
    </Section>
  )
};
export default Management;

const Section = styled.section`
  background: url(${ManagementImage});
  background-size: cover;
  background-position: center center;
  padding: 100px 0;

  ${media.lessThan.md`
    padding: 70px 0;
  `}
`;

const Box = styled.div`
  padding: 50px 0;
  background: rgba(5, 5, 5, 0.25);
  backdrop-filter: blur(6px);

  h2 {
    font-size: 3rem;
    font-weight: 700;
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 400;
    margin: 10px 0;
  }
  p {
    margin: 0;
    max-width: 500px;
    font-size: 1rem;
    font-weight: 400;
  }

  ${media.lessThan.md`
    padding: 30px 20px;
    h2 {
      font-size: 2rem;
      text-align: center;
    }
    h3 {
      font-size: 1.25rem;
      text-align: center;
    }
  `}
`;

const Inner = styled.div`
  max-width: 500px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  img {
    margin-top: 20px;
    width: 84px;
  }
`;
