import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";
import Member from "./Member";

import Mikami from "../../assets/recruit/members/mikami.png";
import Ueno from "../../assets/recruit/members/ueno.png";
import Murakami from "../../assets/recruit/members/murakami.png";
import { media } from "../../lib/utils";

const Members = () => {
  const { t } = useTranslation();

  const members = [{
    name: t("recruit.members.member1.name"),
    position: t("recruit.members.member1.position"),
    image: Mikami,
    history: t("recruit.members.member1.history"),
    interview: {
      reason: t("recruit.members.member1.interview.reason"),
      job: t("recruit.members.member1.interview.job"),
      gap: t("recruit.members.member1.interview.gap"),
      who: t("recruit.members.member1.interview.who"),
      goal: t("recruit.members.member1.interview.goal"),
    },
  }, {
    name: t("recruit.members.member2.name"),
    position: t("recruit.members.member2.position"),
    image: Ueno,
    history: t("recruit.members.member2.history"),
    interview: {
      reason: t("recruit.members.member2.interview.reason"),
      job: t("recruit.members.member2.interview.job"),
      gap: t("recruit.members.member2.interview.gap"),
      who: t("recruit.members.member2.interview.who"),
      goal: t("recruit.members.member2.interview.goal"),
    },
  }, {
    name: t("recruit.members.member3.name"),
    position: t("recruit.members.member3.position"),
    image: Murakami,
    history: t("recruit.members.member3.history"),
    interview: {
      reason: t("recruit.members.member3.interview.reason"),
      job: t("recruit.members.member3.interview.job"),
      gap: t("recruit.members.member3.interview.gap"),
      who: t("recruit.members.member3.interview.who"),
      goal: t("recruit.members.member3.interview.goal"),
    },
  }];
  return (
    <section>
      <Content>
        <Text>
          <div>
            <h2>{t("recruit.members.title")}</h2>
            <h3>{t("recruit.members.subtitle")}</h3>
          </div>
          <p>
            {t("recruit.members.desc")}
          </p>
        </Text>
        {members.map((member, index) => <Member key={index} {...member} />)}
      </Content>
    </section>
  )
};
export default Members;

const Text = styled.div`
  display: flex;
  gap: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #000;
  margin-bottom: 50px;

  h2 {
    margin: 0;
    font-size: 3rem;
    font-weight: 700;
    white-space: nowrap;
  }
  h3 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 400;
    white-space: nowrap;
  }
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  ${media.lessThan.md`
    flex-direction: column;
    gap: 20px;
    h2 {
      text-align: center;
      font-size: 2rem;
    }
    h3 {
      text-align: center;
    }
  `}
`;
