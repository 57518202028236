import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { media } from "../../lib/utils";

import OfficeImage00 from "../../assets/recruit/office/00.jpg";
import OfficeImage01 from "../../assets/recruit/office/01.jpg";
import OfficeImage02 from "../../assets/recruit/office/02.jpg";
import OfficeImage03 from "../../assets/recruit/office/03.jpg";
import OfficeImage04 from "../../assets/recruit/office/04.jpg";
import OfficeImage05 from "../../assets/recruit/office/05.jpg";

const Office = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);

  const images = [
    OfficeImage00,
    OfficeImage01,
    OfficeImage02,
    OfficeImage03,
    OfficeImage04,
    OfficeImage05,
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setSelected((selected + 1) % 6);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, [selected]);

  return (
    <section>
      <Title>{t("recruit.office.title")}</Title>
      <Subtitle>{t("recruit.office.subtitle")}</Subtitle>
      <Wrapper>
        <Indicator>
          {images.map((image, key) => (
            <Dots
              key={key}
              number={key}
              selected={selected}
            />
          ))}
        </Indicator>
        <Slide>
          {images.map((image, key) => (
            <Image
              key={key}
              number={key}
              selected={selected}
              src={image}
              alt="office"
            />
          ))}
        </Slide>
        <MobileSlide>
          {images.map((image, key) => (
            <MobileImage
              key={key}
              number={key}
              selected={selected}
              src={image}
              alt="office"
            />
          ))}
        </MobileSlide>
      </Wrapper>
    </section>
  )
};
export default Office;

const Title = styled.h2`
  text-align: center;
  font-size: 4rem;
  font-weight: 400;
  line-height: 9rem;
  margin-bottom: 10px;

  ${media.lessThan.lg`
    font-size: 2rem;
    line-height: normal;
    font-weight: 700;
    padding: 24px 0;
  `}
`;

const Subtitle = styled.h3`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 50px;
`;

const Wrapper = styled.div`
  ${media.lessThan.lg`
    position: relative;
  `}
`;

const Indicator = styled.div`
  width: fit-content;
  margin: 0 auto;
  display: flex;
  gap: 24px;
  margin-bottom: 40px;
  ${media.lessThan.lg`
    position: absolute;
    z-index: 1;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: 0 auto;
  `}
`;

const Dots = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 0.3s ease;
  background: ${props => props.selected === props.number ? "#AEAEAE" : "#D9D9D9"};

  ${media.lessThan.lg`
    background: ${props => props.selected === props.number ? "#7FC08B" : "#fff"};
  `}
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: calc((100% - 24px) / 9 * 2);
  margin-bottom: 80px;
  overflow: hidden;

  ${media.lessThan.lg`
    display: none;
  `}
`;

const Image = styled.img`
  position: absolute;
  width: calc(33.33% - 8px);
  margin: 0 auto;
  border-radius: 10px;
  transition: all 0.3s ease;

  ${props => {
    switch ((props.number - props.selected + 6) % 6) {
      case 4:
        return `
          opacity: 0.3;
          left: calc(-33.33% + 8px)
        `;
      case 5:
        return `
          opacity: 0.3;
          left: 0;
        `;
      case 0:
        return `
          opacity: 1;
          left: calc(33.33% + 4px);
        `
      case 1:
        return `
          opacity: 0.3;
          left: calc(66.66% + 8px);
        `;
      case 2:
        return `
          opacity: 0.3;
          left: 100%;
        `;
      case 3:
        return `
          opacity: 0;
          transition: none;
        `;
      default:
        return "";
    }
  }}
`;

const MobileSlide = styled.div`
  display: none;
  position: relative;
  width: 100%;
  padding-bottom: 66.66%;
  margin-bottom: 20px;
  overflow: hidden;

  ${media.lessThan.lg`
    display: block;
  `}
`;

const MobileImage = styled.img`
  width: 100%;
  position: absolute;
  transition: all 0.3s ease;

  ${props => {
    switch ((props.number - props.selected + 6) % 6) {
      case 4:
        return `
          left: -200%
        `;
      case 5:
        return `
          left: -100%;
        `;
      case 0:
        return `
          opacity: 1;
          left: 0;
        `
      case 1:
        return `
          left: 100%;
        `;
      case 2:
        return `
          left: 200%;
        `;
      case 3:
        return `
          left: 300%;
          transition: none;
        `;
      default:
        return "";
    }
  }}
`;
