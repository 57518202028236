import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";

import BLogo from "../../assets/logos/black.svg";
import RealEstate from "../../assets/top/real-estate.png";
import Consulting from "../../assets/top/consulting.png";
import { media } from "../../lib/utils";
import { generateLocalizedPath } from "../../lib/languages";
import i18n from "../../i18n/config";

const Business = () => {
  const { t } = useTranslation();

  const lang = i18n.language;

  return (
    <Section>
      <Content>
        <img id="company-logo" src={BLogo} alt="NODOMARU" />
        <Text>
          <Title>
            <span className="eng">{t("top.business.title")}</span>
            {t("top.business.subtitle")}
          </Title>
          <Desc>
            {t("top.business.desc")}
          </Desc>
          <GreenLine />
        </Text>
        <Businesses>
          <Link to={generateLocalizedPath("/business/real-estate", lang)}>
            <BusinessItem>
              <ImgWrapper>
                <img src={RealEstate} alt={t("top.business.real_estate.title")} />
              </ImgWrapper>
              <div className="business-title">
                <h3>{t("top.business.real_estate.title")}</h3>
                <h4>{t("top.business.real_estate.subtitle")}</h4>
              </div>
            </BusinessItem>
          </Link>
          <Link to={generateLocalizedPath("/business/career-consulting", lang)}>
            <BusinessItem>
              <ImgWrapper>
                <img src={Consulting} alt={t("top.business.career_consulting.title")} />
              </ImgWrapper>
              <div className="business-title">
                <h3>{t("top.business.career_consulting.title")}</h3>
                <h4>{t("top.business.career_consulting.subtitle")}</h4>
              </div>
            </BusinessItem>
          </Link>
        </Businesses>
      </Content>
    </Section>
  )
};
export default Business;

const Section = styled.section`
  margin-bottom: 60px;
  #company-logo {
    display: block;
    width: 78px;
    margin: 0 auto;
  }

  ${media.lessThan.md`
    #company-logo {
      width: 40px;
    }
  `}
  ${media.lessThan.sm`
    margin-bottom: 24px;
  `}
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 40px 0;

  ${media.lessThan.md`
    flex-direction: column;
    gap: 12px;
  `}
`;

const Title = styled.h2`
  width: 208px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 0;

  color: #000000;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 58px;
  .eng {
    color: #000;
    font-size: 3rem;
    font-weight: 700;
    line-height: 70px;
  }

  ${media.lessThan.md`
    display: block;
    text-align: center;
    .eng {
      display: block;
      text-align: center;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  `}
`;

const Desc = styled.p`
  margin: 0;
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  ${media.lessThan.md`
    width: 100%;
    border-top: 1px solid #7FC08B;
    border-bottom: 1px solid #7FC08B;
    padding: 20px 0;
  `}
`;

const GreenLine = styled.div`
  height: 8px;
  background: #7FC08B;
  width: 100%;
  max-width: 250px;
  margin-left: auto;
  flex: 1;
`;

const Businesses = styled.div`
  display: flex;
  gap: 40px;

  ${media.lessThan.sm`
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
    border-bottom: 1px solid #7FC08B;
  `}
`;

const BusinessItem = styled.div`
  color: black;
  h3, h4 { margin: 0; }
  h3 { font-weight: 700; }
  h4 { font-weight: 300; }

  transition: 200ms;
  &:hover {
    cursor: pointer;
    img { transform: scale(1.1); }
    h3, h4 { opacity: 0.5 }
  }

  ${media.lessThan.sm`
    .business-title {
      width: 100%;
      max-width: 260px;
      margin: 0 auto;
      padding: 4px;
      border-radius: 10px;
      border: 1px solid #7FC08B;

      text-align: center;
    }
    h3, h4 { font-size: 1.25rem; }
  `}
`;

const ImgWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  margin-bottom: 12px;
  img {
    width: 100%;
    transition: 200ms;
  }

  ${media.lessThan.sm`
    width: calc(100% + ${props => props.theme.content.padding} * 2);
    border-radius: 0;
    margin: 0 -${props => props.theme.content.padding} 12px;
  `}
`;
