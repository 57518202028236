import styled from "styled-components";

import BottomImage from "../../assets/recruit/bottom.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { media } from "../../lib/utils";

const PageBottom = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <img src={BottomImage} alt="members" />
      <Box>
        <Text>
          <h2>{t("recruit.bottom.title")}</h2>
          <h3>{t("recruit.bottom.subtitle")}</h3>
          <span dangerouslySetInnerHTML={{ __html: t("recruit.bottom.desc") }} />
        </Text>
        <ButtonWrapper>
          <Link to="/">
            <Button>{t("recruit.bottom.button")}</Button>
          </Link>
        </ButtonWrapper>
      </Box>
    </Section>
  )
};
export default PageBottom;

const Section = styled.section`
  position: relative;
  img { width: 100%; }

  ${media.lessThan.lg`
    img { display: none; }
    background: url(${BottomImage});
    padding: 24px;
  `}
`;

const Box = styled.div`
  display: flex;
  width: 100%;
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  border-radius: 10px;
  margin: auto;
  padding: 40px;

  ${media.lessThan.lg`
    flex-direction: column;
    position: static;
    margin: 0;

  `}
`;

const Text = styled.div`
  width: 60%;
  h2 {
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
  }
  h3 {
    font-size: 3rem;
    font-weight: 400;
    margin: 0 0 12px;
  }

  ${media.lessThan.lg`
    width: 100%;
    text-align: center;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  ${media.lessThan.lg`
    margin: 24px 0 0;
  `}
`;

const Button = styled.button`
  padding: 20px 40px;color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  border: 4px solid #000;
  border-radius: 10px;
  background: transparent;
`;
