import styled from "styled-components";
import { useTranslation } from "react-i18next";

import TopImage from "../../assets/news/top.png";
import TopLogo from "../../assets/logos/full.svg";

const PageTop = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <img src={TopLogo} alt="NODOMARU" />
      <Title>{t("news.title")}</Title>
      <Subtitle>{t("news.subtitle")}</Subtitle>
    </Section>
  )
};
export default PageTop;

const Section = styled.section`
  background: url(${TopImage});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 450px;
  margin-bottom: 60px;
  padding: 190px 20px 0;

  text-align: center;
  color: #FFF;
  img {
    width: 100%;
    max-width: 450px;
  }
`;

const Title = styled.h1`
  margin: 31px auto 0;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3.5rem;
  align-self: stretch;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

  max-width: 450px;
  border-top: 1px solid #FFF;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
`;
