const translation_zhTW = {
  title: "Nodomaru Co.,Ltd",
  header: {
    top: "TOP",
    real_estate: "REAL ESTATE",
    career_consulting: "CAREER CONSULTING",
    recruit: "RECRUIT",
    ceo_message: "CEO MESSAGE",
  },
  top: {
    title: "享受清静慢活的人生",
    business: {
      title: "Business",
      subtitle: "事業内容",
      desc: "為了實現清靜慢活的和平社會，本公司不僅在日本國內，還在海外開展業務，以幫助每個人能過和平生活的職業諮詢業務和幫助實現和平生活的房地產業務為中心，不斷擴大公司規模。",
      real_estate: {
        title: "房地產",
        subtitle: "REAL ESTATE",
      },
      career_consulting: {
        title: "職業規劃諮詢",
        subtitle: "CAREER CONSULTING",
      },
    },
    staff: {
      title: "工作人員部落格/社交媒體",
      subtitle: "關於公司",
      desc: "公司名稱“Nodomaru”是一個古老的詞，意思是“和平清静”。<br />我們將盡量幫助盡更多的客戶實現“悠閒生活”。",
      links: {
        instagram: "IG",
        blog: "工作人員部落格",
        tiktok: "抖音",
        facebook: "臉書",
      },
    },
    slogan: {
      title: "享受清静慢活的人生",
      mission: {
        title: "任務目標",
        desc: "和有緣人一起走向和平清靜的人生",
      },
      vision: {
        title: "未來願景",
        desc: "通過公司事業項目創建和平的永續發展社會",
      },
      value: {
        title: "VALUE",
        desc: "為“精神幸福和經濟獨立”做貢獻，利用企業人的優勢， 培養自己成為一個既具有體貼他人的“善意”，又具有真正意義上保護他人的“力量”的人。<br /><br/>擁有堅定而柔軟的信念。讓身邊的人幸福而且平靜地生活。",
      },
    },
    foreigner: {
      title: "讓您輕而易舉地在日本找到理想的住家。",
      desc: "提供英語的語言服務<br /><b>如果您從海外移居到日本，請點擊此處</b>",
      button: "了解更多",
    },
    news: {
      title: "新聞及媒體報導",
      button: "查看更多",
    },
    contact: {
      title: "詢問",
      tel: "公司電話",
      email: "查詢電子郵件地址",
      form: {
        name: "名字",
        com_name: "公司名稱",
        email: "電子郵件地址",
        email_confirm: "電子郵件地址（確認用）",
        tel: "電話號碼",
        message: "查詢詳情",
        submit: "提交",
        memo: "*請填寫必須項目",
      },
    },
    company: {
      title: "公司訊息",
      name: "名稱 - Nodomaru Co., Ltd.",
      established: "成立日期 - 2019年1月18日",
      representative: "董事長 - 向仲 駿",
      license1: "不動產交易事業執照號碼 - 東京都知事第104535號",
      license2: "有償就業安置事業執照號碼 - 13-ユ(Yu)-313195",
      address: "地址 - 東京都中央區八丁堀4-11-4 八丁堀前2樓",
      tel: "電話號碼 - 03-6456-4538",
      fax: "傳真號碼 - 03-6456-4537",
      business: {
        title: "事業內容",
        real_estate: "房地產業務",
        placement_agency: "人才招聘業務",
      },
    },
  },
  real_estate: {
    title: "房地產",
    subtitle: "REAL ESTATE",
    rental: {
      title: "房屋租賃服務",
      subtitle: "REAL ESTATE RENTAL SERVICE",
      desc: "在我們的房地產租賃業務中，我們建議並提供最適合客戶需求的房產。 我們服務於從個人到企業的廣泛客戶，並經營各種物業，包括出租公寓、商店和辦公樓。 此外，近年來，隨著全球化的發展，越來越多的企業積極接納外國人，通過與企業的合作，我們還致力於為外國人提供租賃服務之項目。",
    },
    sales: {
      title: "房地產買賣事業部",
      subtitle: "REAL ESTATE SALES",
      desc: "我們的房地產買賣業務提供有關獨棟住宅、公寓、商業房地產等買賣的廣泛諮詢。 我們向海外投資者出售資產價值較高的公寓，我們經驗豐富、會說英語的員工將協助您辦理房產交易相關手續以及稅務和法律方面的諮詢，讓您安心進行交易，無後顧之憂。",
    },
    management: {
      title: "房地產管理事業部",
      subtitle: "REAL ESTATE SALES",
      desc: "我們的房地產管理業務旨在實現業主資產價值最優化。 通過以中央區、江東區為中心的公寓分割管理以及整棟大樓的管理，全力支持業主的資產管理。 我們還管理大量海外業主所擁有的房產，並根據國內市場的趨勢提出適當的管理建議。",
    },
    services: {
      title: "房屋租賃服務",
      item1: {
        title: "扎根於本地的訊息收集能力",
        desc: "我們擁有豐富的當地房地產信息，擅長尋找符合客戶需求的房產，並熟悉最新的市場趨勢。",
      },
      item2: {
        title: "為每位客戶提供量身定制的專業服務",
        desc: "由於該地區有很多海外歸來或從遠方調職而來的客戶，我們也積極提供在線看房服務。",
      },
      item3: {
        title: "通過推薦介紹和回頭客建立了良好的信任關係及口碑",
        desc: "在我們公司，大約 70% 的合同是回頭客以及介紹之客戶。 我們將繼續通過提供高品質的服務來提高客戶滿意度，同時重視人際關係難得的緣分。",
      },
      item4: {
        title: "通過多種語言為廣泛的客戶提供相關服務",
        button: "如果您來自海外，請點擊此處",
        desc: "本公司雇用會說英語、中文、韓語的工作人員，能夠為想要在日本生活的外國客户提供各種服務。 使我們能夠超越國家和語言障礙，滿足廣泛客戶的需求。",
      },
      item5: {
        title: "通過企業合作夥伴關係為員工提供住房服務",
        desc: "我們與東京的許多公司建立了企業合作夥伴關係，並為其員工提供全面的住房服務。 在合同問題頻發的租賃行業中，我們提供值得您放心信賴的專屬服務。",
      },
    },
  },
  career_consulting: {
    title: "職業規劃諮詢",
    subtitle: "CAREER CONSULTING",
    job_change: {
      title: "跳槽就業諮詢服務",
      subtitle: "JOB CHANGE SUPPORT",
      desc: "工作時間佔據了人生生活的大部分。 為了讓工作成為自我實現的場所，我們將從大公司到初創公司的大量職位空缺中向您介紹符合您希望和能力的工作。",
    },
    consulting: {
      title: "招聘諮詢",
      desc: "在人、物、財的管理資源中，“人”是構成企業基礎的最重要的資源。 為了解決貴公司的人力資源招聘以及考慮未來是否能長期穩定工作的問題，我們引進20多歲、30多歲的年輕人力資源來加入貴公司的團隊。",
    },
    matching: {
      title: "公司求職者匹配重點",
      desc: "著眼長遠，實現招聘公司與求職者的最佳匹配。 我們不僅根據匹配條件來考慮候選人，還考慮公司的文化和適合性，以確保雙方的長期成功。",
    },
    services: {
      title: "Career Consulting Services",
      item1: {
        title: "專門針對20多歲、30多歲的年輕人",
        desc: "工作時間佔據了人生生活的大部分。 對於20多歲、30多歲充滿潛力的人，我們希望他們通過工作來拓展自己的潛力，所以我們全力支持他們跳槽，並且一直到找到理想公司為止提供完整服務，無論他們的教育背景或工作經歷如何。 因為我們提供專門針對20多歲、30多歲的人才跳槽服務，所以我們熟悉年輕人的跳槽情況。",
      },
      item2: {
        title: "根據職業顧問所制定的中長期職業規劃",
        desc: "我們尋求“哪種職業選擇最適合您，包括跳槽以外的可能性。 我們的會員均為國家認證職業顧問，將為您精心規劃職業生涯，實現長期職業發展。 我們還定期舉辦自我分析研討會和如何思考換工作的研討會。",
      },
      item3: {
        title: "來自人力資源行業經驗豐富的人員的全面支持服務",
        desc: "我們公司的另一個特點是，我們的許多員工都曾在各大人力資源公司工作過，深諳職場現況。 我們經驗豐富的職業顧問能提供廣泛的一條龍服務，從文件修改到面試準備。 我們為您的求職提供全面支持，同時提供瞬息萬變的就業市場、行業、職位類型等訊息！",
      },
      item4: {
        title: "各類型企業的介紹推薦",
        desc: "我們將介紹符合您人生規劃的公司，從大公司到初創公司，營業銷售到辦公室專職等職業。 我們將為您提供通過個人申請無法獲得的訊息，以及招聘訊息中未包含的真實公司訊息，例如工作場所氣氛文化等。",
      },
      item5: {
        title: "轉職跳槽後的專業職業輔導",
        desc: "轉職跳槽不是最終目標。 作為生活指導者，我們通過將工作變動視為一條線而不是一個點來支持您的生活，並通過工作來豐富您的生活。 即使換工作後，合格的專業指導者也會陪伴您進行輔導，幫助您實現理想的人生藍圖。",
      },
    },
  },
  ceo_message: {
    title: "董事長致詞及成員介紹",
    subtitle: "CEO Message and Members",
    message: {
      title: "CEO MESSAGE",
      ceo_name: "向仲 駿",
      content: "願有緣人過上清靜平穩的生活。<br /><br/>感謝您訪問Nodomaru的主頁。<br /><br />大家知道“Nodomaru”的意思嗎？<br /><br />Nodomaru是一個古老的日語單詞，意思是“平和”。<br /><br />本公司成立於2019年，以“為有緣的人提供安寧的生活”為公司之使命，一直到發展房地產和人力資源招聘業務。<br /><br />在當今多元化的時代，各種思維方式、生活方式不斷呈現，我感到正義邪惡交織在一起。<br /><br />如果您在互聯網上搜索，您很快就會找到“一個正確答案”。<br /><br />然而，由於訊息太多，我覺得找到最適合你的訊息變得越來越困難。<br /><br />通過與每個人的徹底對話，我們為與我們有緣分的人提供真正必要的訊息和支持。<br /><br />生活和工作對於過上平靜的生活至關重要。<br /><br />通過這些業務，我們的目標是為我們的員工、客戶和業務合作夥伴創造一個安寧的社會。<br /><br />Nodomaru 的目標是通過重視人的情感和緣分，成為一家自然而溫暖的公司，並以真正的 Nodomaru 方式為世界做出貢獻。<br /><br />",
    },
    members: {
      member1: {
        name: "片山慎梧",
        position: "租賃仲介部門",
        history: "曾在一家大型招聘機構擔任招聘顧問，後來在一家房地產新創公司設立了銷售部門。 目前從事租賃仲介部門的銷售和新員工的培訓工作。",
        private: "最喜歡的食物是豆腐",
      },
      member2: {
        name: "上野ひより(Hiyori)",
        position: "租賃仲介部門",
        history: "大學期間曾到加拿大留學學習英語。 目前，她正在利用這些經驗來支持與國際海外相關的運營工作。對美容情報方面很感興趣，也經常上傳房地產和美容訊息到各個社交媒體上。",
        private: "各種各樣化妝品的收集者",
      },
      member3: {
        name: "廣島 明奈",
        position: "總經理",
        history: "旅外一陣子後，在一家外資保險公司獲得了5年的銷售經驗。之後，成為一名健康管理指導者，一直以來支持著很多人的美麗和健康。 根據本身各種經驗，目前活躍於會計、總務、人力資源和銷售等領域。",
        private: "喜歡自然和美麗的事物",
      },
      member4: {
        name: "見上のどか(Nodoka)",
        position: "人力資源招聘部",
        history: "曾在一家大型招聘機構擔任招聘顧問，目前負責人力資源部門的組建。 2022年，獲得了國家職業顧問資格，也為20多歲、30多歲的人提供職業諮詢服務。",
        private: "喜歡山勝過一日三餐",
      },
    },

  },
  recruit: {
    title: "人員招募",
    subtitle: "RECRUITING",
    members: {
      title: "團隊成員",
      subtitle: "MEMBER COMMENT",
      desc: "我們每天都在為 \"和有緣人一起走向和平清靜的人生\"而努力。我們正在尋找認同我們的願景和使命，並能共同迎接挑戰的人。",
      member_descs: {
        history: "迄今為止的背景工作，例如",
        reason: "選擇 NODOMARU 的原因。",
        job: "您目前的職責是什麼？",
        gap: "在您加入公司之前和之後，是否存在差距？",
        who: "你想與什麼樣的人共事？",
        goal: "您未來的目標是什麼？",
        open_button: "訪談",
        close_button: "關閉",
      },
      member1: {
        name: "見上のどか(Nodoka)",
        position: "人事招聘部",
        history: "在一家大型人力資源公司工作了兩年，負責向企業推薦人力資源廣告的銷售工作。",
        interview: {
          reason: "我對“創建自己公司”的未來感到興奮，而不是加入一家已經創建的公司。 當我剛決定加入公司時，公司只有三名成員，一切都還未成型，所以周圍反對的聲浪很多，我有點焦慮，但我相信能夠和這些人創造出令人興奮的成果和未來。 我有強烈的願望這樣做，所以我相信自己的直覺並決定加入這家公司。",
          job: "我參與外部各方的招聘、公共關係和職業諮詢等工作。 人力資源和職業諮詢都與工作有關，但我覺得這是一份有意義的工作，可以讓我參與到人們通過工作改變的時刻。",
          gap: "比我想像的更自由。 我有一個百分百支持我做任何我想做的事情的環境，但因為我有如此多的自由，我也感到有責任履行我的角色。 公司的氣氛總體上平穩而溫暖，但我也覺得自己能夠以良好的緊張感來履行自己的職責，因為我被賦予了責任。",
          who: "我認同Nodomaru的“精神幸福和經濟獨立”的價值觀，並認為為了讓周圍的人幸福，你需要做人的善良和做生意的力量。這對於那些有同感的人來說是完美的環境。",
          goal: "今後，我也想繼續以人力資源官的身份參與招聘和人力資源開發工作，培養能夠在世界上發揮積極作用的人才。",
        },
      },
      member2: {
        name: "上野ひより(Hiyori)",
        position: "租赁中介部",
        history: "大學期間，對出國留學很感興趣，所以在必修課程中學習了英語和韓語。",
        interview: {
          reason: "因為我想在Nodomaru嘗試一下銷售工作。 當我第一次來到Nodomaru時，我很驚訝地發現這是一家充滿笑聲的公司。 從找工作開始，我對工作只有負面的印象，對成為一名工作成年人感到非常沮喪，但我第一次對一家公司感到興奮和期待，認為我可以在Nodomaru享受有趣的工作生活。 最初我聽說公司沒有招聘應屆畢業生的計劃，但仍然給了我實習的機會，所以我迫切希望加入公司。",
          job: "我在租賃仲介部門工作。 在協助找房的過程中認識了很多人，很開心。 而且，當有人感謝我並將這份工作委託給我的那一刻，我真的很高興我選擇了銷售職業。 我原本很害羞，不太喜歡與陌生人交流，所以我對銷售工作感到擔心，但我喜歡在Nodomaru工作，這裡讓我慢慢成長。",
          gap: "沒有特別的差距隔閡。 我有過一個月的實習經歷，所以在進公司之前就有很多和Nodomaru互動的機會，而且每個人在進公司之前和之後都是這樣的表裏如一。",
          who: "我是一個喜歡刺激、渴望個人成長的人。 Nodomaru 擁有支持個人成長的環境。 我想享受將個人成長與公司成長聯繫在一起的興奮感。",
          goal: "我想在海外白手起家創辦一家公司。 為此，我將繼續磨練我的業務技能。",
        },
      },
      member3: {
        name: "村上潤",
        position: "租赁管理部",
        history: "先在一家鋼鐵貿易公司工作了三年，之後為一家大型電子製造商從事銷售工作。",
        interview: {
          reason: "跳槽的原因是因為我認為這是一個讓我可以像以前一樣充滿活力工作的環境。 在我之前的工作中，我有一種強烈的感覺，我是為了工作而活，在那裡工作讓我感到不舒服。 有點類似於工作日潛入水中不能呼吸，週末才能休息一下的窒息感。 當時我剛好有機會與向仲董事長交談，我覺得NODOMARU應該能夠消除這種不舒服的感覺，於是我義無反顧地決定加入這家公司。這是一個可以讓我充分發揮自己的潛力，取得成果的環境。",
          job: "我們正在努力擴大業務，同時也在租賃管理部門提供現場支持服務。 同時創建和經營一家企業，有時會很困難，但我發現從概念到執行所有事情都可以自行決定是一件很有趣的事情。",
          gap: "沒有特別的差距隔閡。 它沒有經過任何裝飾，所以給人的感覺就是它本來的樣子。",
          who: "一個永遠不會放棄自己思考的人。 只要我們不放棄，前進的路就永遠存在，並且Nodomaru提供一個每個人都能跟進的系統環境。",
          goal: "目標是成為一名高層主管，從更大更高的視野角度來創建Nodomaru。",
        },
      },
    },
    office: {
      title: "Office Environment",
      subtitle: "辦公室相簿",
    },
    bottom: {
      title: "加入團隊",
      subtitle: "關於公司",
      desc: "公司名稱“Nodomaru”是一個古老的詞語，意思是“心境變得平穩”。 我們將儘可能幫助更多的客戶實現“清静慢活‘的理想。。",
      button: "瞭解更多訊息",
    },
  },
  news: {
    title: "新聞及媒體報導",
    subtitle: "PRESS RELEASE/NEWS",
    categories: "類別",
  },
};
export default translation_zhTW;
