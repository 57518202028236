export const languages = ["en", "ja", "zh-CN", "zh-TW"];

export const getLangFromUrl = (location = window.location) => {
  const possibleLang = location.pathname.split("/")[1];
  return languages.includes(possibleLang) ? possibleLang : "ja";
};

export const generateLocalizedPath = (path, lang) => {
  if (lang === "ja") return path;
  return `/${lang}${path === "/" ? "" : path}`;
};

export const removeLangFromPath = (path) => {
  const possibleLang = path.split("/")[1];
  return (languages.includes(possibleLang) ? path.replace(`/${possibleLang}`, "") : path) || "/";
};
