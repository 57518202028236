import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { media } from "../../lib/utils";

const Member = ({ name, position, image, history, interview }) => {
  const { t } = useTranslation();

  const [view, setView] = useState(false);
  return (
    <MemberItem>
      <InfoWrapper className="flex">
        <Photo src={image} alt={name} />
        <Info>
          <div>{name}</div>
          <div>{position}</div>
        </Info>
      </InfoWrapper>
      <Text>
        <h4>{t("recruit.members.member_descs.history")}</h4>
        <p>{history}</p>
        {view ? (
          <>
            <h4>{t("recruit.members.member_descs.reason")}</h4>
            <p>{interview.reason}</p>
            <h4>{t("recruit.members.member_descs.job")}</h4>
            <p>{interview.job}</p>
            <h4>{t("recruit.members.member_descs.gap")}</h4>
            <p>{interview.gap}</p>
            <h4>{t("recruit.members.member_descs.who")}</h4>
            <p>{interview.who}</p>
            <h4>{t("recruit.members.member_descs.goal")}</h4>
            <p>{interview.goal}</p>
            <Button type="close" onClick={() => setView(false)}>
              {t("recruit.members.member_descs.close_button")}
            </Button>
          </>
        ) : (
          <Button type="open" onClick={() => setView(true)}>
            {t("recruit.members.member_descs.open_button")}
          </Button>
        )}
      </Text>
    </MemberItem>
  )
};
export default Member;

const MemberItem = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 40px;

  ${media.lessThan.lg`
    flex-direction: column;
  `}
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 24px;

  ${media.lessThan.lg`
    margin: 0 auto;
  `}
`;

const Photo = styled.img`
  border-radius: 10px;
  width: 200px;
  height: 200px;

  ${media.lessThan.lg`
    width: 150px;
    height: 150px;
  `}
`;

const Info = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  width: 230px;

  ${media.lessThan.lg`
    width: 100%;
  `}
`;

const Text = styled.div`
  width: calc(100% - 478px);
  h4 {
    font-size: 1.25rem;
    font-weight: 700;
  }
  font-size: 1.25rem;
  font-weight: 400;
  p { margin-bottom: 24px; }

  ${media.lessThan.lg`
    width: 100%;
  `}
`;

const Button = styled.button`
  display: block;
  background-color: transparent;
  border-radius: 8px;
  border: 4px solid ${props => props.type === "open" ? "#7FC08B" : "rgba(5, 5, 5, 0.25)"};
  color: ${props => props.type === "open" ? "#7FC08B" : "rgba(5, 5, 5, 0.25)"};
  font-size: 1.5rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
`;
