import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../../Layouts";

import SalesImage from "../../../assets/business/career-consulting/consulting.png";
import { media } from "../../../lib/utils";

const Consulting = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Content>
        <Box>
          <h2>{t("career_consulting.consulting.title")}</h2>
          <p>{t("career_consulting.consulting.desc")}</p>
        </Box>
      </Content>
    </Section>
  )
};
export default Consulting;

const Section = styled.section`
  background: url(${SalesImage});
  background-size: cover;
  background-position: center center;
  padding: 170px 0;

  ${media.lessThan.md`
    padding: 80px 0;
  `}
`;

const Box = styled.div`
  color: white;
  margin-left: auto;
  max-width: 500px;
  h2 {
    font-size: 3rem;
    font-weight: 700;
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 400;
    margin: 10px 0;
  }
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
  }

  ${media.lessThan.md`
    max-width: 100%;
    background: rgba(255, 255, 255, 0.65);
    padding: 24px;
    color: black;
    h2 {
      font-size: 2rem;
      text-align: center;
    }
  `}
`;
