import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";
import { media } from "../../lib/utils";

const Company = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Content>
        <Box>
          <MapField>
            <iframe
              title="googlemap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d810.2801068633797!2d139.77683814087706!3d35.67403660287668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bd51fd87b15%3A0x99c9b022f10ffdd1!2z44ixTm9kb21hcnXvvIhOb2RvbWFydSBDby4sTHRk77yJ!5e0!3m2!1sja!2sjp!4v1692228090050!5m2!1sja!2sjp"
              width="100%"
              height="450"
              style={{ border: 0, borderRadius: "10px" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </MapField>
          <Info>
            <Title>{t("top.company.title")}</Title>
            {t("top.company.name")}<br/>
            {t("top.company.established")}<br/>
            {t("top.company.representative")}<br/>
            {t("top.company.address")}<br/>
            {t("top.company.tel")}<br/>
            {t("top.company.fax")}<br/>
            <br/>
            {t("top.company.business.title")}<br/>
            {t("top.company.business.real_estate")}<br/>
            {t("top.company.business.placement_agency")}<br/>
            <br />
            {t("top.company.license1")}<br/>
            {t("top.company.license2")}<br/>
          </Info>
        </Box>
      </Content>
    </Section>
  )
};
export default Company;

const Section = styled.section`
  margin-bottom: 60px;
`;

const Box = styled.div`
  display: flex;
  gap: 40px;

  ${media.lessThan.lg`
    display: block;
  `}
`;

const MapField = styled.div`
  width: 60%;
  border-radius: 10px;
  overflow: hidden;

  ${media.lessThan.lg`
    width: calc(100% + ${props => props.theme.content.padding} * 2);
    margin: 0 -${props => props.theme.content.padding} 40px;
    border-radius: 0;
  `}
`;

const Info = styled.div`
  width: 40%;

  ${media.lessThan.lg`
    width: 100%;
  `}
`;

const Title = styled.h2`
  color: #000;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 12px;

  ${media.lessThan.lg`
    text-align: center;
    font-size: 2rem;
  `}
`;
