const translation_en = {
  title: "Nodomaru Co.,Ltd",
  header: {
    top: "TOP",
    real_estate: "REAL ESTATE",
    career_consulting: "CAREER CONSULTING",
    recruit: "RECRUIT",
    ceo_message: "CEO MESSAGE",
  },
  top: {
    title: "BE PEACEFUL and live an idyllic life.",
    business: {
      title: "Business",
      subtitle: "",
      desc: "In order to realize a tranquil society, we are developing our business not only in Japan but also overseas, focusing on the career consulting business to help people lead a tranquil life and the real estate business to realize a tranquil lifestyle.",
      real_estate: {
        title: "REAL ESTATE",
        subtitle: "",
      },
      career_consulting: {
        title: "CAREER CONSULTING",
        subtitle: "",
      },
    },
    staff: {
      title: "Staff Blog & SNS",
      subtitle: "About Us",
      desc: "The company name \"Nodomaru\" means \"to become tranquil\" in the old Japanese language. We will contribute to the realization of \"Nodomaru Life\" for as many customers as possible.",
      links: {
        instagram: "Instagram",
        blog: "STAFF BLOG",
        tiktok: "TikTok",
        facebook: "Facebook",
      },
    },
    slogan: {
      title: "Live an idyllic life.",
      mission: {
        title: "mission",
        desc: "An idyllic life for all who are fortunate enough to be a part of it.",
      },
      vision: {
        title: "vision",
        desc: "Creating a Steady Society through Business",
      },
      value: {
        title: "VALUE",
        desc: "To become a person who has the strength to protect others in the true sense of the word by developing the ability to be a businessperson, while having \"kindness\" to think of others with a spirit of contribution and \"spiritual happiness and financial independence\".<br /><br/>To have unshakable and flexible beliefs and to live life as it is in a peaceful manner while making those around you happy.",
      },
    },
    foreigner: {
      title: "Let's make your home hunt in Japan a breeze, in English!",
      desc: "All support will be provided in English.<br /><b>If you are moving from overseas to Japan, please click here.</b>",
      button: "LEARN MORE",
    },
    news: {
      title: "NEWS & PRESS RELEASE",
      button: "VIEW MORE",
    },
    contact: {
      title: "Contact Us",
      tel: "Tel",
      email: "Inquiry e-mail address",
      form: {
        name: "Name",
        com_name: "Your Company Name",
        email: "Email address",
        email_confirm: "Email address (for confirmation)",
        tel: "Phone number",
        message: "Inquiry Details",
        submit: "SUBMIT",
        memo: "*Please fill in the required fields",
      },
    },
    company: {
      title: "COMPANY INFORMATION",
      name: "Name - Nodomaru Co.,Ltd",
      established: "Established - January 18, 2019",
      representative: "Representative - Shun Mukainaka",
      license1: "Building Lots and Buildings Transaction Business License No. - Governor of Tokyo (1) No. 104535",
      license2: "Fee-charging Employment Placement Business License No. - 13-Yu-313195",
      address: "Location - Hatchobori Front 2F, 4-11-4 Hatchobori, Chuo-ku, Tokyo",
      tel: "Tel - 03-6456-4538",
      fax: "FAX - 03-6456-4537",
      business: {
        title: "Business",
        real_estate: "Real Estate Business",
        placement_agency: "Placement Agency",
      },
    },
  },
  real_estate: {
    title: "REAL ESTATE",
    subtitle: "",
    rental: {
      title: "REAL ESTATE RENTAL",
      subtitle: "",
      desc: "In our real estate leasing business, we offer the best properties to meet the needs of our customers. We handle a wide range of properties, including rental condominiums, stores, and office buildings, for both individual and corporate clients. In addition, with the recent globalization, an increasing number of companies are proactively accepting foreign nationals, and through corporate tie-ups with companies, we also provide rental support for foreign nationals as one of our strengths.",
    },
    sales: {
      title: "REAL ESTATE SALES",
      subtitle: "",
      desc: "In our real estate transaction business, we provide a wide range of consultation services for the purchase and sale of single-family homes, condominiums, and commercial real estate. We sell condominiums with high asset value to overseas investors, and our experienced staff, who are fluent in English, can provide consultation on procedures, taxes, and legal matters related to real estate transactions, thereby establishing a service that allows customers to conduct transactions with peace of mind.",
    },
    management: {
      title: "REAL ESTATE SALES",
      subtitle: "",
      desc: "Our property management business is designed to maximize the value of owners' assets. We provide full support for owners' asset management through the management of condominium units and whole buildings, mainly in Chuo and Koto wards. We also manage many properties for overseas owners and make appropriate proposals in line with domestic market trends.",
    },
    services: {
      title: "Real Estate Rental Services",
      item1: {
        title: "Ability to gather community-based information",
        desc: "We have a wealth of information about the area and are well versed in finding the properties you are looking for and the latest market trends.",
      },
      item2: {
        title: "Tailored support for each customer",
        desc: "As the area has many customers returning from overseas or relocating from far away, we are actively offering online previews.",
      },
      item3: {
        title: "Track record of trust through referrals and repeat business",
        desc: "Approximately 70% of all contracts are with repeat customers or customers referred by referrals. We will continue to pursue customer satisfaction through high quality services, while cherishing the relationships between people.",
      },
      item4: {
        title: "Multilingual support for a wide range of customersMultilingual support for a wide range of customers",
        button: "Click here if you are overseas",
        desc: "We employ staff who speak English as well as Chinese and Korean, enabling us to accommodate foreign customers who wish to live in Japan. This enables us to meet the needs of a wide range of customers, transcending nationality and language barriers.",
      },
      item5: {
        title: "Housing support for employees through corporate partnerships",
        desc: "We have established corporate partnerships with many companies based in Tokyo and provide comprehensive support for employee housing. In the rental industry, where contract disputes are a common occurrence, we provide exclusive services that can be entrusted to our clients with peace of mind.",
      },
    },
  },
  career_consulting: {
    title: "CAREER CONSULTING",
    subtitle: "",
    job_change: {
      title: "JOB CHANGE SUPPORT",
      subtitle: "",
      desc: "Working time is a large part of your life. We introduce jobs that match your hopes and aptitude from a large number of jobs from major companies to startups so that work can be a place of self-realization.",
    },
    consulting: {
      title: "Recruitment Consulting",
      desc: "Among management resources (people, goods, and money), \"people\" are the most important resource that forms the foundation of a company. In order to solve your company's recruitment issues with human resources, we introduce young talents in their 20s and 30s who we hope will work for a long and stable period of time in the future.",
    },
    matching: {
      title: "Matching",
      desc: "We match the best candidates with the best companies for the long term. We do this not only because the conditions match, but also because we consider the corporate culture and the aptitude of the job seeker.",
    },
    services: {
      title: "Career Consulting Services",
      item1: {
        title: "Specializing in young people in their 20s and 30s",
        desc: "\"Working time\" accounts for a large percentage of your life. We have a wealth of potential for people in their 20s and 30s, and we want them to expand their possibilities through their work.",
      },
      item2: {
        title: "Mid- to long-term career planning by career consultants",
        desc: "We pursue the essential support of \"what career choice is best for you\", including options other than changing jobs. Our members, who are nationally certified career consultants, carefully design your career to help you build a long-term career. Workshops for self-analysis and seminars on how to think about changing jobs are also held on a regular basis.",
      },
      item3: {
        title: "Thorough support by experienced personnel from the human resources industry",
        desc: "One of the characteristics of our company is that many of our staff come from major human resource companies with extensive experience in career support. Our experienced career advisors provide one-stop support ranging from document correction to interview preparation. We provide thorough support for your job search, providing information on the rapidly changing job market, industry, and job type!",
      },
      item4: {
        title: "Introduction to a wide variety of companies",
        desc: "We introduce you to companies that match your life plans, from major companies to start-ups, focusing on sales, administration, and other types of jobs. We will provide you with information that you cannot obtain by self-application, as well as real company information such as workplace atmosphere that is not listed in the job postings.",
      },
      item5: {
        title: "Career coaching after changing jobs",
        desc: "Changing jobs is not the goal. As a life coach, I will support your life by helping you to enrich your life through your work. Our qualified professional coaches will continue to work with you after your job change to help you achieve your ideal figure through coaching.",
      },
    },
  },
  ceo_message: {
    title: "CEO Message and Members",
    subtitle: "",
    message: {
      title: "CEO MESSAGE",
      ceo_name: "Shun Mukainaka",
      content: "Thank you for visiting the website of Nodomaru Co.<br /><br />Do you know the meaning of \"Nodomaru\"?<br /><br />Nodomaru is an ancient Japanese word meaning \"to become tranquil\".<br /><br />Our company was established in 2019, and we have been developing our real estate and recruiting business under the company's mission, \"A tranquil life for those who are fortunate enough to have it\".<br /><br />In today's age of diversity, it seems that justice is intermingled with the various ways of thinking and living presented.<br /><br />If you look up the Internet, you will quickly find \"one right answer. However, because of the abundance of information, I feel that it is becoming more difficult to find the best for oneself.<br /><br />Through a solid dialogue with each person, we will provide the truly necessary information and support to those with whom we have a relationship.<br /><br />\"Living\" and \"Working\" are essential to lead a tranquil life.<br /><br />Through these businesses, we aim to create a society where employees, customers, and business partners can live idyllic lives.<br /><br />By valuing human feelings and connections, we, Nodomaru, aim to be a natural and warm company and contribute to the world in our own Nodomaru way.",
    },
    members: {
      member1: {
        name: "Shingo Katayama",
        position: "Leasing Brokerage Department",
        history: "Formerly worked as a recruiting consultant for a major recruiting firm, and later established a sales department at a real estate venture company. Currently engaged in sales and training of new employees in the rental brokerage department.",
        private: "I love tofu.",
      },
      member2: {
        name: "Hiyori Ueno",
        position: "Leasing Brokerage Department",
        history: "Studied abroad in Canada for language study during her university years. Currently, she is using her experience to provide operational support to the International Division. A beauty enthusiast, she is also active on social networking sites that disseminate real estate and beauty information.",
        private: "Habit of collecting cosmetics",
      },
      member3: {
        name: "Akina Hiroshima",
        position: "Senior Managing Director",
        history: "After wandering abroad, he worked as a sales representative for a foreign insurance company for five years. After that, she worked as a health manager, supporting many people's beauty and health. With a variety of experiences, she is now active in accounting, general affairs, human resources, and sales.",
        private: "I love nature and beautiful things.",
      },
      member4: {
        name: "Nodoka Mikami",
        position: "Human Resources & Recruiting Department",
        history: "Formerly a recruiting consultant at a major recruiting firm and currently in charge of setting up the Human Resources Department, he obtained his national certification as a career consultant in 2022 and also provides career support for people in their 20s and 30s.",
        private: "I like mountains better than three meals a day",
      },
    },

  },
  recruit: {
    title: "RECRUITING",
    subtitle: "",
    members: {
      title: "MEMBER",
      subtitle: "",
      desc: "We are working daily with the mission \"An idyllic life for all who are fortunate enough to be a part of it.\". We are looking for people who share our vision and mission and can take on challenges together.",
      member_descs: {
        history: "Background and work to date",
        reason: "Reasons for choosing NODOMARU",
        job: "What are your current duties?",
        gap: "Was there a gap between before and after you joined?",
        who: "What kind of people do you want to work with?",
        goal: "What are your future goals?",
        open_button: "INTERVIEW",
        close_button: "CLOSE",
      },
      member1: {
        name: "Nodoka Mikami",
        position: "Human Resources Department",
        history: "I worked for a major human resources company for two years, where I was a sales representative proposing human resources advertising to corporate clients.",
        interview: {
          reason: "I was excited about the prospect of \"creating my own company,\" rather than joining a company that had been created for me. When I first decided to join the company, there were only three members on the board of directors and nothing had been created yet, so there was a lot of opposition from those around me, and I was very anxious! I decided to trust my instincts and join the company.",
          job: "As a human resource manager, I am responsible for recruiting and public relations, as well as career consulting for external parties. Both HR and career consulting are related to \"work,\" and I feel that it is a very rewarding job because I can be involved in the moments when people change through their work.",
          gap: "I had more freedom than I expected. The environment is 100% supportive of what I want to do, but I also feel a sense of responsibility to fulfill my role because of the high degree of freedom. The atmosphere of the company is basically calm and warm, but I feel that I am able to fulfill my role with a good sense of urgency because of the responsibility that is given to me.",
          who: "This is the perfect environment for those who share Nodomaru's values of \"spiritual happiness and financial independence\" and feel that both kindness as a person and strength as a business person are necessary to make those around them happy.",
          goal: "I would like to continue to be involved in recruiting and human resource development work as a human resource manager and produce human resources who can play an active role in the world.",
        },
      },
      member2: {
        name: "Hiyori Ueno",
        position: "Rental Brokerage Department",
        history: "In college, I was interested in foreign countries, so I studied English and Korean in a department that required study abroad.",
        interview: {
          reason: "I wanted to try sales at Nodomaru. When I first came to Nodomaru, I was shocked to see such a company full of smiling faces. Since I was job hunting, I had only negative images of working and was very depressed about becoming a member of society, but I was excited about the company for the first time, thinking that I could have a pleasant working life at Nodomaru. At first I heard that the company did not plan to hire new graduates, but I was given the opportunity to intern, so I was desperate to join the company.",
          job: "I am a sales representative in the Rental Agency Department. I enjoy meeting many people through room searches. I am also very happy when people thank me for entrusting them with my services, and I am truly glad that I chose a career in sales. I have always been shy and did not like to get involved with new people, and I was worried about working as a sales person, but I am enjoying sales at Nodomaru, which supports individual growth.",
          gap: "There was no particular gap. I had many opportunities to come in contact with Nodomaru before I joined the company because I had a one-month internship, but everyone was just as they are before and after joining the company, just as they are, in their natural state.",
          who: "I am a person who likes excitement and is eager for personal growth. I would like to enjoy the excitement of personal growth leading to the growth of the company together.",
          goal: "I would like to start up from zero overseas. I will continue to hone my business skills to achieve this.",
        },
      },
      member3: {
        name: "Jun Murakami",
        position: "Rental Management Department",
        history: "I worked for a steel trading company for three years, selling to a major electronics manufacturer.",
        interview: {
          reason: "I thought it was an environment where I could work as I am. At my previous job, I had a strong sense that I was creating a personality for work, and I worked with a sense of discomfort. I felt like I was diving in the water on weekdays and breathing on weekends. At that time, I had a chance to talk with Mr. Mukainaka, the representative of Nodomaru, and I felt that Nodomaru would be able to dispel my sense of discomfort. I decided to join Nodomaru because I felt that if there was an environment where I could achieve results by being myself as I really am, then I had no choice but to jump in.",
          job: "I am working to expand the business while providing on-site support in the Rental Management Division. It is sometimes difficult because I am simultaneously creating and running the business, but I find it very interesting to be able to take the business from conception to execution at my own discretion.",
          gap: "There was no particular gap. I got the impression that it was really just as it was because it was not presented in an ornate way.",
          who: "He is a person who never gives up thinking for himself. As long as you don't give up, there is a way, and Nodomaru has a system where everyone follows up.",
          goal: "I am now promoted to the board of directors and will continue to create Nodomaru from a much larger perspective.",
        },
      },
    },
    office: {
      title: "Office Environment",
      subtitle: "",
    },
    bottom: {
      title: "Join Our Team",
      subtitle: "About Us",
      desc: "The company name \"Nodomaru\" means \"to become tranquil\" in the old Japanese language.<br />We will contribute to the realization of \"Nodomaru Life\" for as many customers as possible.",
      button: "LEARN MORE",
    },
  },
  news: {
    title: "PRESS RELEASE/NEWS",
    subtitle: "",
    categories: "Categories",
  },
};
export default translation_en;
