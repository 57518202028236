import styled from "styled-components";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { generateLocalizedPath, removeLangFromPath } from "../lib/languages";
import { media } from "../lib/utils";


const LanguageSelector = ({ current }) => {
  const navigate = useNavigate();

  const selectLang = (e) => {
    navigate(generateLocalizedPath(removeLangFromPath(window.location.pathname), e.target.value));
  };
  return (
    <Wrapper>
      <Form.Select onChange={selectLang} value={current}>
        <option value="ja">🇯🇵 日本語</option>
        <option value="en">🇬🇧 English</option>
        <option value="zh-CN">🇨🇳 简体中文</option>
        <option value="zh-TW">🇹🇼 繁體中文</option>
      </Form.Select>
    </Wrapper>
  );
};
export default LanguageSelector;


const Wrapper = styled.div`
  display: inline-block;
  float: right;
  margin: 13px 0;

  ${media.lessThan.lg`
    margin-right: -20px;
  `}
`;