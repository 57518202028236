import { useTranslation } from "react-i18next";

import PageTop from "../components/business/PageTop";
import FirstBusinessDetail from "../components/business/FirstBusinessDetail";
import Sales from "../components/business/real-estate/Sales";
import Management from "../components/business/real-estate/Management";
import Services from "../components/business/real-estate/Service";

import TopImage from "../assets/business/real-estate/top.png";
import RentalService from "../assets/business/real-estate/rental-service.png";

const BusinessRealEstate = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTop title={t("real_estate.title")} subtitle={t("real_estate.subtitle")} topImage={TopImage} />
      <FirstBusinessDetail
        title={t("real_estate.rental.title")}
        subtitle={t("real_estate.rental.subtitle")}
        desc={t("real_estate.rental.desc")}
        image={RentalService}
      />
      <Sales />
      <Management />
      <Services />
    </>
  )
};

export default BusinessRealEstate;

