import PageTop from "../components/ceo-message/PageTop";
import Message from "../components/ceo-message/Message";
import Members from "../components/ceo-message/Members";

const Top = () => {
  return (
    <>
      <PageTop />
      <Message />
      <Members />
    </>
  )
};

export default Top;
