import PageTop from "../components/recruit/PageTop";
import Members from "../components/recruit/Members";
import PageBottom from "../components/recruit/PageBottom";
import Office from "../components/recruit/Office";

const Top = () => {
  return (
    <>
      <PageTop />
      <Members />
      <Office />
      <PageBottom />
    </>
  )
};

export default Top;
