import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";

import Office from "../../assets/top/office.jpeg";

import { media } from "../../lib/utils";

const Slogan = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Content>
        <Box>
          <Title>{t("top.slogan.title")}</Title>
          <MandV className="flex">
            <FlexItem>
              <b>{t("top.slogan.mission.title")}</b><br/>
              {t("top.slogan.mission.desc")}
            </FlexItem>
            <FlexItem>
              <b>{t("top.slogan.vision.title")}</b><br/>
              {t("top.slogan.vision.desc")}
            </FlexItem>
          </MandV>
          <Value>{t("top.slogan.value.title")}</Value>
          <Text dangerouslySetInnerHTML={{ __html: t("top.slogan.value.desc") }} />
        </Box>
      </Content>
    </Section>
  )
};
export default Slogan;

const Section = styled.section`
  background: url(${Office});
  background-size: cover;
  background-position: center center;
  padding: 80px 0;
  margin-bottom: 60px;
`;

const Box = styled.div`
  background: rgba(255, 255, 255, 0.75);
  padding: 40px 80px;
  border-radius: 10px;
  text-align: center;

  ${media.lessThan.md`
    padding: 12px;
  `}
`;

const Title = styled.p`
  font-size: 4rem;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding-bottom: 40px;
  border-bottom: 1px solid #000;

  ${media.lessThan.md`
    font-size: 1rem;
    padding-bottom: 12px;
  `}
`;

const MandV = styled.div`
  display: flex;
  gap: 40px;

  ${media.lessThan.md`
    flex-direction: column;
    gap: 0;
    padding: 12px 0;
  `}
`;

const FlexItem = styled.div`
  width: 50%;
  padding: 32px 0;

  font-size: 2rem;
  font-weight: 400;
  b { font-weight: 700; }

  ${media.lessThan.md`
    width: 100%;
    padding: 0;
    font-size: 1rem;
  `}
`;

const Value = styled.h3`
  font-size: 4rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1em;
  text-align: center;
  color: #7FC08B;
  border-top: 1px solid #000;

  &:after {
    content: "";
    margin-left: -1em;
  }

  ${media.lessThan.md`
    font-size: 1rem;
    letter-spacing: normal;
    &:after {
      display: none;
    }
    padding: 4px 0;
    margin: 0;
  `}
`;

const Text = styled.p`
  font-size: 2rem;
  margin: 0;

  ${media.lessThan.md`
    font-size: 1rem;
  `}
`;
