import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../../Layouts";

import SalesImage from "../../../assets/business/real-estate/sales.png";
import { media } from "../../../lib/utils";

const Sales = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Content>
        <Box>
          <h2>{t("real_estate.sales.title")}</h2>
          <h3>{t("real_estate.sales.subtitle")}</h3>
          <p>
            {t("real_estate.sales.desc")}
          </p>
        </Box>
      </Content>
    </Section>
  )
};
export default Sales;

const Section = styled.section`
  background: url(${SalesImage});
  background-size: cover;
  background-position: center center;
  padding: 170px 0;

  ${media.lessThan.md`
    padding: 80px 0;
  `}
`;

const Box = styled.div`
  padding: 24px;
  background: rgba(255, 255, 255, 0.25);
  h2 {
    font-size: 3rem;
    font-weight: 700;
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 400;
    margin: 10px 0;
  }
  p {
    margin: 0;
    max-width: 500px;
    font-size: 1rem;
    font-weight: 400;
  }

  ${media.lessThan.md`
    h2 {
      font-size: 2rem;
      text-align: center;
    }
    h3 {
      font-size: 1.25rem;
      text-align: center;
    }
  `}
`;
