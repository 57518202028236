const translation_ja = {
  title: "株式会社Nodomaru",
  header: {
    top: "TOP",
    real_estate: "REAL ESTATE",
    career_consulting: "CAREER CONSULTING",
    recruit: "RECRUIT",
    ceo_message: "CEO MESSAGE",
  },
  top: {
    title: "BE PEACEFUL のどかな人生を",
    business: {
      title: "Business",
      subtitle: "事業内容",
      desc: "当社はのどまる社会の実現のため、一人一人がのどかな暮らしを実現するための不動産事業と、のどかな人生を歩むためのキャリアコンサルティング事業を中心に日本のみならず、海外に向けても事業を展開しています。",
      real_estate: {
        title: "不動産",
        subtitle: "REAL ESTATE",
      },
      career_consulting: {
        title: "キャリアコンサルティング",
        subtitle: "CAREER CONSULTING",
      },
    },
    staff: {
      title: "Staff Blog & SNS",
      subtitle: "会社について",
      desc: "社名の「のどまる」とは古語で「のどかになる」という意味です。<br />一人でも多くのお客様の「のどまる人生」の実現に貢献致します。",
      links: {
        instagram: "Instagram",
        blog: "STAFF BLOG",
        tiktok: "TikTok",
        facebook: "Facebook",
      },
    },
    slogan: {
      title: "のどかな人生を",
      mission: {
        title: "mission",
        desc: "縁ある人にのどかな人生を",
      },
      vision: {
        title: "vision",
        desc: "事業を通じてのどまる社会を創る",
      },
      value: {
        title: "VALUE",
        desc: "「精神的幸福と経済的自立」 貢献心に富み相手を思いやれる\"優しさ\"を持ちながら、企業人としての力をつけることで本当の意味で人を守れる\"強さ\"も合わせ持つ人間になること<br /><br/>ブレないしなやかな信念を持ち、周りの人を幸せにしながら穏やかにありのままに生きていくこと",
      },
    },
    foreigner: {
      title: "Let's make your home hunt in Japan a breeze, in English!",
      desc: "全て英語でサポートいたします。<br /><b>海外から日本にお引越しの方はこちら</b>",
      button: "LEARN MORE",
    },
    news: {
      title: "NEWS & PRESS RELEASE",
      button: "VIEW MORE",
    },
    contact: {
      title: "お問い合わせ",
      tel: "Tel",
      email: "問い合わせメールアドレス",
      form: {
        name: "お名前",
        com_name: "貴社名",
        email: "メールアドレス",
        email_confirm: "メールアドレス（確認用）",
        tel: "電話番号",
        message: "お問い合わせ内容",
        submit: "SUBMIT",
        memo: "*必須項目に入力してください",
      },
    },
    company: {
      title: "COMPANY INFORMATION",
      name: "名称 - 株式会社Nodomaru",
      established: "設立 - 2019年1月18日",
      representative: "代表者 - 向仲 駿",
      license1: "宅地建物取引業免許番号 - 東京都知事（1）第104535号",
      license2: "有料職業紹介事業許可番号 - 13-ユ-313195",
      address: "所在地 - 東京都中央区八丁堀4丁目11-4 八丁堀フロント2F",
      tel: "Tel - 03-6456-4538",
      fax: "FAX - 03-6456-4537",
      business: {
        title: "事業内容",
        real_estate: "不動産事業",
        placement_agency: "人材紹介業",
      },
    },
  },
  real_estate: {
    title: "不動産",
    subtitle: "REAL ESTATE",
    rental: {
      title: "不動産賃貸事業",
      subtitle: "REAL ESTATE RENTAL SERVICE",
      desc: "当社の不動産賃貸事業では、お客様のニーズに合わせた最適な物件をご提案しております。個人のお客様から法人のお客様まで幅広く対応し、賃貸マンション、店舗・オフィスビルなど様々な物件を取り扱っております。また、近年のグローバル化に伴い外国人の受入れを積極的に行う企業が増えており、企業様との法人提携を通じて、外国籍の方の賃貸サポートも強みとして取り組んでおります。",
    },
    sales: {
      title: "不動産売買事業",
      subtitle: "REAL ESTATE SALES",
      desc: "当社の不動産売買事業では、一戸建てやマンション、事業用不動産などの売買に関するご相談に幅広く対応しています。海外の投資家様向けに資産性の高いマンションの販売を行なっており、不動産取引に伴う手続きや、税金・法律に関するご相談にも英語対応可能で経験豊富なスタッフが対応し、安心してお取引いただけるサービスを構築しております。",
    },
    management: {
      title: "不動産管理事業",
      subtitle: "REAL ESTATE MANAGEMENT",
      desc: "当社の不動産管理事業では、オーナー様の資産価値を最大限に高めるための管理事業を行っています。中央区・江東区を中心としたマンションの区分管理及び、一棟管理を通じて、オーナー様の資産運用を全面的にサポートしております。海外オーナー様の物件も多数管理しており、国内市場の動向に合わせた適切なご提案をしております。",
    },
    services: {
      title: "Real Estate Rental Services",
      item1: {
        title: "地域に根ざした<br />情報収集力",
        desc: "当社は地域の情報を豊富に持ち合わせており、お客様が求める物件の見つけ出しや、最新の市場動向に精通しています。",
      },
      item2: {
        title: "お客様一人ひとりに<br />合わせたサポート",
        desc: "海外からの帰任、遠方からの転勤等のお客様が多いエリアである為、オンライン内見も積極的に行っております。",
      },
      item3: {
        title: "ご紹介とリピート<br />による信頼の実績",
        desc: "当社では、全契約における約7割がリピーターやご紹介のお客様で占めております。今後も人と人とのご縁を大切にしながら、質の高いサービスによりお客様の満足を追求してまいります。",
      },
      item4: {
        title: "多言語対応で<br />幅広い顧客層に対応",
        button: "海外の方はこちら",
        desc: "当社では、英語をはじめ、中国語・韓国語を話せるスタッフを雇用しており、日本に住みたい外国人のお客様にも対応が可能です。これにより、国籍や言語の壁を越えて、幅広いお客様のニーズに応えることができます。",
      },
      item5: {
        title: "法人提携により<br />従業員様の住居サポート",
        desc: "都内に拠点を置く多くの企業様と法人提携を結び、従業員様の住居サポートを一括して対応しております。契約トラブルの絶えない賃貸業界において、安心してお任せ頂ける専属のサービスを提供しております。",
      },
    },
  },
  career_consulting: {
    title: "キャリアコンサルティング",
    subtitle: "CAREER CONSULTING",
    job_change: {
      title: "転職支援",
      subtitle: "JOB CHANGE SUPPORT",
      desc: "人生で多くの割合を占めるのが『働く時間』です。仕事が自己実現の場となるよう、大手企業からスタートアップまで、多数の求人の中からあなたの希望や適性に合った求人をご紹介。",
    },
    consulting: {
      title: "採用コンサルティング",
      desc: "経営資源のヒト・モノ・カネの中でも、企業の土台となるもっとも重要な資源となるのが「ヒト」。人的リソースで貴社の採用課題を解決するべく、「これから長く安定して働いてもらいたい」といった20代・30代の若手人材を紹介します。",
    },
    matching: {
      title: "マッチング",
      desc: "長期的な視点を見据えた求人企業と求職者との最適なマッチング。ただ単に条件がマッチしているからという理由だけでなく、企業文化や求職者の適性も考慮し、双方にとって長期的な成功につながる採用を実現します。",
    },
    services: {
      title: "Career Consulting Services",
      item1: {
        title: "<span>20代・30代の若手に</span><span>特化</span>",
        desc: "人生で多くの割合を占めるのが『働く時間』です。可能性に溢れる20代・30代の方に、「仕事を通して自分の可能性を拡げてほしい」という想いから、学歴・職歴に関わらず、入社までしっかりと転職をサポート。20代・30代の転職を専門としてきたからこそ、若手の転職事情を熟知しています。",
      },
      item2: {
        title: "<span class=\"one-line\">キャリアコンサルタント</span><span>による</span><span>中長期的な</span><span>キャリア設計</span>",
        desc: "「転職以外の選択肢も含め、あなたにとってどんなキャリア選択がベストなのか」という本質的な支援を追求。国家資格キャリアコンサルタントを保有しているメンバーが長期的なキャリア形成に向け、丁寧にキャリア設計をします。自己分析をするためのワークショップや、転職の考え方セミナーなども定期的に開催。",
      },
      item3: {
        title: "<span>経験豊富な</span><span>人材業界</span><span>出身者</span><span>による</span><span>徹底サポート</span>",
        desc: "キャリア支援に精通してきた大手人材会社出身者が多いのも弊社の特徴。経験豊富なキャリアアドバイザーが、書類の添削から面接対策までワンストップで幅広く支援します。変化の速い転職市場や、業界、職種などの情報も提供しながらあなたの転職活動を徹底サポート！",
      },
      item4: {
        title: "多種多様な企業を紹介",
        desc: "営業・事務などの職種を中心に、大手からスタートアップまであなたのライフプランに合わせた企業をご紹介。自己応募では得られない情報はもちろん、職場の雰囲気など、求人票には載っていないリアルな企業情報もお伝えします。",
      },
      item5: {
        title: "転職後の<br />キャリアコーチング",
        desc: "転職がゴールではありません。転職を「点」ではなく「線」で捉え、仕事を通して人生が豊かになるような、ライフコーチとしてあなたの人生をサポートします。転職後も資格保有者のプロコーチがコーチングを通してあなたの理想の姿に伴走します。",
      },
    },
  },
  ceo_message: {
    title: "代表挨拶とメンバー",
    subtitle: "CEO Message and Members",
    message: {
      title: "CEO MESSAGE",
      ceo_name: "向仲 駿",
      content: "縁ある人にのどかな人生を<br /><br />この度は株式会社Nodomaruのホームページをご覧頂きありがとうございます。<br /><br />皆様、「のどまる(和まる)」という意味をご存知でしょうか。<br /><br />のどまるとは日本の古語で「のどかになる」という意味です。<br /><br />弊社は2019年に設立、「縁ある人にのどかな人生を」を会社のMissionとして掲げ、不動産事業・人材紹介事業を展開してまいりました。<br /><br />今日の多様性の時代、様々な考え方や生き方が提示され、正義が交錯しているように思います。<br /><br />ネットを調べれば、「1つの正解」はすぐに出てきます。<br /><br />しかし情報が溢れているからこそ、自分にとってのベストを見つけることは寧ろ難しくなっていると感じます。<br /><br />一人ひとりとのしっかりとした対話を通じて、縁ある方に本当に必要な情報を提供し、サポートさせて頂きます。<br /><br />のどかな人生を送るために必要不可欠な「住むこと」「働くこと」。<br /><br />これらの事業を通して、社員・お客様・お取引様がのどかになる社会を目指します。<br /><br />私たちNodomaruは、人間の感情・繋がりを大切にすることで、自然体で温かみのある企業を目指し、Nodomaruらしく、世の中に貢献してまいります。<br />",
    },
    members: {
      member1: {
        name: "片山慎梧",
        position: "賃貸仲介部",
        history: "元大手人材紹介会社で採用コンサルを経験、後に不動産ベンチャー企業にて営業部を立ち上げる。現在は賃貸仲介部の営業と新人教育に従事。",
        private: "お豆腐が大好き",
      },
      member2: {
        name: "上野ひより",
        position: "賃貸仲介部",
        history: "大学時代は語学勉強のためカナダへ留学。現在はその経験を活かし国際課の業務サポートをしている。美容好きで、不動産情報と美容情報を発信するSNSにも力を入れている。",
        private: "コスメ収集癖の持ち主",
      },
      member3: {
        name: "廣島明奈",
        position: "専務取締役",
        history: "海外放浪した後、外資系保険会社にて5年間営業を経験。その後、健康管理士として多くの人の美容健康サポートし現在に至る。様々な経験から、現在は経理、総務、人事、営業とマルチに活動中。",
        private: "自然と美しいものが好き",
      },
      member4: {
        name: "見上のどか",
        position: "人事採用部",
        history: "元大手人材紹介会社で採用コンサルを経験し、現在は人事部の立ち上げを担当。2022年に国家資格キャリアコンサルタントを取得し、20代〜30代向けにキャリア支援も実施している。",
        private: "三度の飯より山が好き",
      },
    },

  },
  recruit: {
    title: "リクルート",
    subtitle: "RECRUITING",
    members: {
      title: "メンバー",
      subtitle: "MEMBER COMMENT",
      desc: "Nodomaruは、「縁ある人にのどかな人生を」をmissionに日々活動しています。私達のビジョンやミッションに共感し、共に挑戦できる方を募集しています。",
      member_descs: {
        history: "これまでの経歴業務内容など",
        reason: "NODOMARUを選んだ理由",
        job: "今の業務内容は？",
        gap: "入社前と入社後のギャップはあった？",
        who: "どんな人と一緒に働きたい？",
        goal: "今後の目標は？",
        open_button: "インタビュー",
        close_button: "CLOSE",
      },
      member1: {
        name: "見上のどか",
        position: "人事採用部",
        history: "大手人材会社にて2年勤務し、法人向けに人材広告を提案する営業をしていました。",
        interview: {
          reason: "創られた会社に入るのではなく、”自分で会社を創っていく”という未来にワクワクしたからです。入社を決めた当初はメンバーは役員の３人のみ、あまりにもまだ何も出来上がっていない状態だったので周りからの反対も多く、不安も大きかったのですが、この人たちとワクワクする未来を創りたい！という気持ちの方が強く、自分の直感を信じて入社を決めました。",
          job: "人事として採用や広報業務、社外向けにキャリアコンサルをしています。人事もキャリアコンサルも、「仕事」にまつわる仕事ですが、仕事を通して人が変わる瞬間に携わることができる、やりがいのある仕事だと感じています。",
          gap: "想定以上に自由度が高かったです。自分がやりたいと思ったことには100パーセント応援してくれる環境がありますが、自由度が高い分自分の役割を果たす責任も感じています。会社の雰囲気は基本的に穏やかで温かいのですが、与えられる責任もあるため、良い緊張感の中で役割を全うできている感覚があります。",
          who: "Nodomaruのバリューである、「精神的幸福と経済的自立」に共感し、自分の周りの人を幸せにするためには、人としての優しさと企業人としての強さ、どちらも必要だと感じている人にはぴったりな環境です。",
          goal: "今後も人事として採用や人材開発業務に携わり、世界で活躍できる人材を輩出していきたいです。",
        },
      },
      member2: {
        name: "上野ひより",
        position: "賃貸仲介部",
        history: "大学時代は海外に興味があったこともあり、留学必須の学科で英語と韓国語を学んでいました。",
        interview: {
          reason: "Nodomaruで営業をやってみたいと思ったからです。初めてNodomaruにきた時、こんなに笑顔で溢れる会社があるのかと衝撃を受けました。就活の時から働くことにマイナスなイメージしかなく、社会人になることがとても憂鬱でしたが、Nodomaruでなら楽しい社会人生活を送れるではないかと初めて会社に対してワクワクしました。当初は新卒を雇う予定はないと聞いていましたが、インターンという機会を頂けたので、入社するために必死でした。",
          job: "賃貸仲介部で営業をしています。お部屋探しを通して沢山の出会いがありとても楽しいです。また、私に任せてよかったと感謝してもらえる瞬間は営業の道を選んでよかったと心から思えます。もともと人見知りで新しい人と関わることもあまり好きではなく営業として働くことに不安もありましたが、個人の成長を応援してくれるNodomaruでの営業は楽しいです。",
          gap: "特にギャップはありませんでした。私は1ヶ月間インターンシップを経験したため入社前からNodomaruと触れる機会が多くありましたが、入社前も入社後もみんなそのままでありのままの自然体でした。",
          who: "ワクワクすることが好きで自分の成長に貪欲な人です。Nodomaruは個人の成長を応援してくれる環境があります。個人の成長が会社の成長に繋がるワクワクを一緒に楽しみたいです＾＾",
          goal: "海外で0から立ち上げをやってみたいです。そのためにビジネススキルを磨き続けます。",
        },
      },
      member3: {
        name: "村上潤",
        position: "賃貸管理部",
        history: "鉄鋼商社にて3年勤務し、大手電機メーカー向けに営業をおこなっていました。",
        interview: {
          reason: "ありのまま生き生きと働ける環境だと思ったからです。前職では、仕事用の人格を作り出しているといった感覚が強く、違和感を感じながら働いていました。平日は水に潜り、土日で息をするという感覚が近しいかもしれません。そんなとき代表の向仲と話をする機会があり、Nodomaruならその違和感が払拭できるのではないか、と感じました。ありのまま生き生きとした自分で成果を出せる環境があるのなら、飛び込むしかないだろう、という気持ちで入社を決意しました。",
          job: "賃貸管理事業部にて現場対応をしながら、事業拡大に向けた動きをしています。事業を作ることと回すことを同時にしているので、大変なこともありますが、自分の裁量で構想から実行まで行える点は、非常におもしろいと感じています。",
          gap: "特にギャップはありませんでした。飾った見せ方をされていないので、本当にそのままという印象です。",
          who: "自分で考えることを諦めない人です。諦めない限り道はありますし、全員でフォローアップする体制がNodomaruにはあります。",
          goal: "役員に昇格し、もっと大きな視点でNodomaruを創っていくことです。",
        },
      },
    },
    office: {
      title: "Office Environment",
      subtitle: "オフィスギャラリー",
    },
    bottom: {
      title: "Join Our Team",
      subtitle: "会社について",
      desc: "社名の「のどまる」とは古語で「のどかになる」という意味です。<br />一人でも多くのお客様の「のどまる人生」の実現に貢献致します。",
      button: "LEARN MORE",
    },
  },
  news: {
    title: "プレスリリース・ニュース",
    subtitle: "PRESS RELEASE/NEWS",
    categories: "カテゴリー",
  },
};
export default translation_ja;
