import styled from "styled-components";

export const Layout = styled.div`
  max-width: ${props => props.theme.page.width};
  margin: 0 auto;
`;

export const Content = styled.div`
  max-width: ${props => props.theme.content.width};
  padding: 0 ${props => props.theme.content.padding};
  margin: 0 auto;
`;