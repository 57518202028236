import { useTranslation } from "react-i18next";

import PageTop from "../components/business/PageTop";
import FirstBusinessDetail from "../components/business/FirstBusinessDetail";
import Consulting from "../components/business/career-consulting/Consulting";
import Matching from "../components/business/career-consulting/Matching";
import Services from "../components/business/career-consulting/Service";

import TopImage from "../assets/business/career-consulting/top.png";
import TopImageMobile from "../assets/business/career-consulting/mobile-top.jpeg";
import JobChange from "../assets/business/career-consulting/job-change.png";

const BusinessRealEstate = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTop
        title={t("career_consulting.title")}
        subtitle={t("career_consulting.subtitle")}
        topImage={TopImage}
        mobileTop={TopImageMobile}
      />
      <FirstBusinessDetail
        title={t("career_consulting.job_change.title")}
        subtitle={t("career_consulting.job_change.subtitle")}
        desc={t("career_consulting.job_change.desc")}
        image={JobChange}
      />
      <Consulting />
      <Matching />
      <Services />
    </>
  )
};

export default BusinessRealEstate;

