import { useRef } from "react";
import axios from "axios";
import { Slide } from "react-slideshow-image";

import styled from "styled-components";
import { Form, Carousel } from "react-bootstrap";

import YouTube from "react-youtube";

import { Content } from "../components/Layouts";
import { media } from "../lib/utils";

import BLogo from "../assets/logos/black.svg";
import WLogo from "../assets/logos/white.svg";

import PageTopPc from "../assets/foreigner-lp1/top/pc.png";
import PageTopSp from "../assets/foreigner-lp1/top/sp.png";

import PropertiesTokyo1 from "../assets/foreigner-lp1/properties-tokyo/01.png";
import PropertiesTokyo2 from "../assets/foreigner-lp1/properties-tokyo/02.png";
import PropertiesTokyo3 from "../assets/foreigner-lp1/properties-tokyo/03.png";

import PropertySearch from "../assets/foreigner-lp1/service/property-search.jpg";
import PropertyManagement from "../assets/foreigner-lp1/service/property-management.jpg";
import OneStopExpertService from "../assets/foreigner-lp1/service/one-stop-expert.jpg";

import FooterPc from "../assets/foreigner-lp1/footer/pc.jpg";
import FooterSp from "../assets/foreigner-lp1/footer/sp.jpg";

import LINE from "../assets/foreigner-lp1/icons/line.png";
// import Twitter from "../assets/foreigner-lp1/icons/twitter.png";
import WhatsApp from "../assets/foreigner-lp1/icons/whatsapp.png";

import ReviewImg01 from "../assets/foreigner-lp1/reviews/01.jpg";
import ReviewImg02 from "../assets/foreigner-lp1/reviews/02.jpg";
import ReviewImg03 from "../assets/foreigner-lp1/reviews/03.jpg";
import ReviewImg04 from "../assets/foreigner-lp1/reviews/04.jpg";
import ReviewImg05 from "../assets/foreigner-lp1/reviews/05.jpg";
import ReviewImg06 from "../assets/foreigner-lp1/reviews/06.jpg";
import ReviewImg07 from "../assets/foreigner-lp1/reviews/07.jpg";
import ReviewImg08 from "../assets/foreigner-lp1/reviews/08.jpg";


const ForeignerLp1 = () => {
  const tokyoRef = useRef(null);
  const searchRef = useRef(null);
  const managementRef = useRef(null);
  const oneStopRef = useRef(null);
  const contactRef = useRef(null);

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const messageRef = useRef(null);
  const inputrefs = [nameRef, emailRef, phoneRef, messageRef];

  const topImages = [{
    pc: PageTopPc,
    sp: PageTopSp,
  }];

  const scrollTo = (ref) => ref.current?.scrollIntoView({behavior: 'smooth'});

   const fireGtmEvent = (eventName) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
    });
   };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("your-name", nameRef.current.value);
    formData.append("your-company", "foreigner lp 1");
    formData.append("your-email", emailRef.current.value);
    formData.append("your-confirmemail", emailRef.current.value);
    formData.append("your-phonenumber", phoneRef.current.value);
    formData.append("your-message", messageRef.current.value);
    axios.post("https://ndmr.co.jp/blog/index.php?rest_route=/contact-form-7/v1/contact-forms/39/feedback",formData)
      .then((res) => {
        console.log(res);
        if (res.data.status === "mail_sent") inputrefs.forEach((input) => input.current.value = "");
        fireGtmEvent("foreinerlp1-submitForm");
      });
  };

  const properties = [{
    src: PropertiesTokyo1,
    name: "1LDK Apartment - River view",
    address: "Chuo-ku, Tokyo",
    info: "[Size] 40㎡ [Rent] $7K",
  }, {
    src: PropertiesTokyo2,
    name: "2LDK Apartment - Spacious living room",
    address: "Koto-ku, Tokyo",
    info: "[Size] 50㎡ [Rent] $5K",
  }, {
    src: PropertiesTokyo3,
    name: "3LDK Apartment - Rainbow Bridge view",
    address: "Minato-ku, Tokyo",
    info: "[Size] 60㎡ [Rent] $5K",
  }];

  return (
    <>
      <PageTop>
        <img src={BLogo} alt="nodomaru" id="logo" />
        <TopImages
          indicators={false}
          controls={false}
        >
          {topImages.map((color, i) => <TopImage src={color} key={i} />)}
        </TopImages>
        <BlackLayer />
        <TextField>
          <img src={BLogo} alt="nodomaru" id="sp-logo" />
          <Title>Transform Your life in Tokyo with your dream property</Title>
          <Small color="dark" className="mv20">Make your property search in Tokyo peaceful and hassle-free with our expert agent.</Small>
          <ButtonSecs>
            <ButtonSec>
              <Small color="dark" className="mv20">Tell us what you would like to do</Small>
              <Button color="light" width="80%" onClick={() => scrollTo(tokyoRef)}>RENT</Button>
            </ButtonSec>
            <ButtonSec>
              <Small color="dark" className="mv20" />
              <Button color="dark" width="80%" onClick={() => scrollTo(contactRef)}>CONTACT US</Button>
            </ButtonSec>
          </ButtonSecs>
        </TextField>
      </PageTop>
      <StyledYouTube
        videoId="af9m8TUvItY"
        opts={{
          width: "100%",
          height: "100%",
          playerVars: {
            autoplay: 0,
            controls: 0,
            modestbranding: 1,
            rel: 0,
          },
        }}
      />
      <Content>
        <Subtitle color="light" ref={tokyoRef} >Properties for Rent in Tokyo</Subtitle>
      </Content>
      <Properties>
        {properties.map((property, i) => (
          <Property key={i}>
            <PropertyImage src={property.src} />
            <PropertyName>{property.name}</PropertyName>
            <PropertyAddress className="two-line">{property.address}</PropertyAddress>
            <PropertyAddress className="two-line">{property.info}</PropertyAddress>
          </Property>
        ))}
      </Properties>
      <SpProperties>
        <Slide
          autoplay={true}
          duration={5000}
          transitionDuration={300}
        >
          {properties.map((property, i) => (
            <Property key={i}>
              <PropertyImage src={property.src} />
              <PropertyName>{property.name}</PropertyName>
              <PropertyAddress className="two-line">{property.address}</PropertyAddress>
              <PropertyAddress className="two-line">{property.info}</PropertyAddress>
            </Property>
          ))}
        </Slide>
      </SpProperties>
      <DarkSection>
        <ServiceItem ref={searchRef}>
          <Image src={PropertySearch} />
          <Detail>
            <Subtitle color="dark">Our Service</Subtitle>
            <Small color="dark" className="mv20">Find your dream unit conveniently with our support, including pre-public and undisclosed properties.</Small>
            {/* <Button color="light" onClick={() => scrollTo(contactRef)} sp="none">Connect with our Agents</Button> */}
          </Detail>
        </ServiceItem>
        <ServiceItem ref={managementRef}>
          <Detail>
            <Subtitle color="dark">Property Management</Subtitle>
            <Small color="dark" className="mv20">Maximize the potential of your property with our professional management services. Our team of experts will work with you to deliver tailored solutions that fit your unique requirements.</Small>
            {/* <Button color="light" onClick={() => scrollTo(contactRef)} sp="none">Connect with our Agents</Button> */}
          </Detail>
          <Image src={PropertyManagement} />
        </ServiceItem>
        <ServiceItem ref={oneStopRef}>
          <Image src={OneStopExpertService} />
          <Detail>
            <Subtitle color="dark">One Stop Expert Service</Subtitle>
            <Small color="dark" className="mv20">From visa to home, we've got you covered! Enjoy a hassle-free one stop service for foreigners that includes English-speaking staff and streamlined room search.</Small>
            <Button color="light" onClick={() => scrollTo(contactRef)}>Connect with our Agents</Button>
          </Detail>
        </ServiceItem>
      </DarkSection>
      <Content>
        <Subtitle color="light" ref={tokyoRef} className="mt20" >Reviews</Subtitle>
        <Reviews className="mt50">
          <Review src={ReviewImg01} />
          <Review src={ReviewImg02} />
          <Review src={ReviewImg03} />
          <Review src={ReviewImg04} />
          <Review src={ReviewImg05} />
          <Review src={ReviewImg06} />
          <Review src={ReviewImg07} />
          <Review src={ReviewImg08} />
        </Reviews>
        <SpReviews>
          <Slide
            autoplay={true}
            duration={5000}
            transitionDuration={300}
          >
            <Review src={ReviewImg01} />
            <Review src={ReviewImg02} />
            <Review src={ReviewImg03} />
            <Review src={ReviewImg04} />
            <Review src={ReviewImg05} />
            <Review src={ReviewImg06} />
            <Review src={ReviewImg07} />
            <Review src={ReviewImg08} />
          </Slide>
        </SpReviews>
      </Content>
      <DarkSection>
        <Content>
          <Subtitle color="dark" border="none" ref={contactRef}>Contact</Subtitle>
          <ContactForm onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Input type="text" required ref={nameRef} />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Input type="email" required ref={emailRef} />
            </Form.Group>
            <Form.Group controlId="tel">
              <Form.Label>Telephone</Form.Label>
              <Input type="number" required ref={phoneRef} />
            </Form.Group>
            <Form.Group controlId="details">
              <Form.Label>Inquiry Details<Optional> ＊optional</Optional></Form.Label>
              <Input as="textarea" ref={messageRef} />
            </Form.Group>
            <div className="text-center">
              <Button color="light">Send</Button>
            </div>
          </ContactForm>
        </Content>
      </DarkSection>
      <SnsWrapper>
        <Sns>
          <Mes>Feel free to reach us directly!</Mes>
          <SnsItems>
            <SnsItem type="LINE" target="_blank" href="https://lin.ee/umwO5rH" onClick={() => fireGtmEvent("foreinerlp1-line")}>
              <img src={LINE} alt="LINE" />
              LINE
            </SnsItem>
            {/* <SnsItem type="Twitter">
              <img src={Twitter} alt="Twitter" />
              Twitter
            </SnsItem> */}
            <SnsItem type="WhatsApp" target="_blank" href="https://wa.me/message/2AJIDGDLAENVN1" onClick={() => fireGtmEvent("foreinerlp1-whatsapp")}>
              <img src={WhatsApp} alt="WhatsApp" />
              WhatsApp
            </SnsItem>
          </SnsItems>
        </Sns>
      </SnsWrapper>
      <Footer>
        {/* <Links>
          <li><span onClick={() => scrollTo(tokyoRef)}>Properties for Rent in Tokyo</span></li>
          <li><span onClick={() => scrollTo(searchRef)}>Property Search</span></li>
          <li><span onClick={() => scrollTo(managementRef)}>Property Management</span></li>
          <li><span onClick={() => scrollTo(oneStopRef)}>One Stop Expert Service</span></li>
          <li><span onClick={() => scrollTo(contactRef)}>Contact</span></li>
        </Links> */}
        Nodomaru Co. Ltd<br />
        Tel : 03-6456-4538<br />
        E-mail: info@ndmr.co.jp<br />
        <Policy>
          <img src={WLogo} alt="nodomaru" />
          Privacy Policy<br />
          Copyright © 2023 All Rights Reserved.
        </Policy>
      </Footer>
    </>
  );
};
export default ForeignerLp1;

const PageTop = styled.section`
  position: relative;
  width: 100%;
  padding-bottom: 100vh;
  min-height: 500px;

  color: white;

  #logo {
    width: 80px;
    position: absolute;
    z-index: 10;
    left: 50px;
    top: 50px;
  }
  ${media.lessThan.md`
    min-height: 600px;
    #logo {
      display: none;
    }
  `}
`;

const TopImages = styled(Carousel)`
  position: absolute;
  width: 100%;
  height: 100%;
  .carousel-inner {
    width: 100%;
    height: 100%;
  }
`;

const TopImage = styled(Carousel.Item)`
  background: url(${props => props.src.pc});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  ${media.lessThan.md`
    background: url(${props => props.src.sp});
    background-size: cover;
    background-position: center;
  `}
`;

const BlackLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

const TextField = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: absolute;
  width: 60%;
  right: 0;
  top: 0;
  bottom: 0;

  margin: auto;
  padding-right: 50px;

  #sp-logo {
    display: none;
  }

  ${media.lessThan.md`
    width: auto;
    left: 0;
    padding: 0 20px;
    #sp-logo {
      width: 80px;
      display: block;
      margin: 0 auto 20px;
    }
  `}
`;

const Title = styled.h1`
  font-size: 2.5rem;
  letter-spacing: 0.2rem;
  line-height: 4rem;
  margin: 0;
  font-weight: bold;

  ${media.lessThan.md`
    font-size: 1.5rem;
    letter-spacing: normal;
    line-height: 2rem;
  `}
`;

const Small = styled.div`
  color: ${props => props.color === "dark" ? "white" : "black"};
  position: relative;
  font-size: 1rem;
  min-height: 1lh;
`;

const ButtonSecs = styled.div`
  display: flex;

  ${media.lessThan.md`
    flex-flow: column;
  `}
`;

const ButtonSec = styled.div`
  width: 50%;

  ${media.lessThan.md`
    width: 100%;
    text-align: center;
  `}
`;

const Button = styled.button`
  background: ${props => props.color === "light" ? "white" : "black"};

  width: ${props => props.width || "100%"};
  border: 0;
  padding: 2rem 0;
  color: ${props => props.color === "light" ? "black" : "white"};
  font-weight: bold;

  cursor: pointer;
  ${media.lessThan.md`
    padding: 1.5rem 0;
    ${props => props.sp === "none" && `display: none;`}
  `}
`;

const Subtitle = styled.h2`
  color: ${props => props.color === "light" ? "black" : "white"};
  line-height: 4rem;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  ${props => props.border !== "none" && `
    &:after {
      content: "";
      display: block;
      width: 80px;
      height: 100%;
      border-bottom: 2px solid #d7c500;
    }
  `}
  ${media.lessThan.md`
    line-height: 3rem;
    font-size: 1.5rem;
  `}
`;

const StyledYouTube = styled(YouTube)`
  padding-top: 56.25%;
  margin: 20px 0;
  width: 100%;
  position: relative;

  iframe {
    width: 70%;
    height: 70%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    ${media.lessThan.md`
      width: 90%;
      height: 90%;
    `}
  }
`;

const Properties = styled.div`
  display: flex;
  gap: 40px;
  padding: 0 40px;

  margin: 0 auto 60px;
  max-width: ${props => props.theme.content.width};
  overflow-x: scroll;
  ${media.lessThan.md`
    display: none;
  `}
`;

const Property = styled.div`
  flex: 0 0 auto;
  width: calc((100% - 80px) / 2.7);
  padding: 40px 0;
  ${media.lessThan.md`
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  `}
`;

const PropertyImage = styled.div`
  width: 100%;
  padding-bottom: 100%;
  background: url(${props => props.src});
  background-size: cover;
  background-position: center;
`;

const PropertyName = styled.h5`
  margin: 10px 0 0;
  font-weight: bold;
  font-size: 1rem;
`;

const PropertyAddress = styled.div``;

const SpProperties = styled.div`
  display: none;
  padding: 0 20px;
  .react-slideshow-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  ${media.lessThan.md`
    display: block;
  `}
`;

const DarkSection = styled.div`
  background: black;
  padding: 100px 0;
`;

const ServiceItem = styled.div`
  display: flex;
  margin: 80px auto;
  padding: 0 20px;
  max-width: ${props => props.theme.content.width};
  ${media.lessThan.md`
    margin: 0 auto;
    flex-flow: column;
    padding: 40px;
  `}
`;

const Image = styled.div`
  width: 50%;
  background: url(${props => props.src});
  background-size: cover;
  background-position: center;
  position: relative;
  padding-bottom: 30%;
  ${media.lessThan.md`
    width: 100%;
    order: 1;
    padding-bottom: 60%;
  `}
`;

const Detail = styled.div`
  width: 50%;
  padding: 0 50px;
  ${media.lessThan.md`
    width: 100%;
    order: 2;
    padding: 20px 0;
  `}
`;

const Reviews = styled.div`
  display: flex;
  gap: 40px;
  padding: 0 40px;

  margin: 40px auto;
  overflow-x: scroll;
  border: 2px solid #d7c500;
  ${media.lessThan.md`
    display: none;
  `}
`;

const Review = styled.img`
  display: block;
  flex: 0 0 auto;
  width: calc((100% - 40px) / 1.7);
  padding: 40px 0;
  ${media.lessThan.md`
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
  `}
`;

const SpReviews = styled.div`
  display: none;
  margin: 20px -30px;
  border: 2px solid #d7c500;
  .react-slideshow-container {
    width: 100%;
    margin: 0 auto;
  }
  ${media.lessThan.md`
    display: block;
  `}
`;

const ContactForm = styled(Form)`
  color: white;
  border: 2px solid #d7c500;
  padding: 50px;
  margin-bottom: 100px;
  font-weight: bold;
  ${media.lessThan.md`
    padding: 20px;
  `}
`;

const Input = styled(Form.Control)`
  width: 100%;
  border: none;
  background: #f5f5f5;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 2rem;
  ${props => props.as === "textarea" && `height: 10rem;`}
`;

const Optional = styled.span`
  color: #d7c500;
  font-style: italic;
`;

const SnsWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 30%;
  background: url(${FooterPc});
  background-size: cover;
  background-position: center;
  ${media.lessThan.md`
    background: url(${FooterSp});
    background-size: cover;
    background-position: center;
    position: static;
    padding: 80px 0;
  `}
`;

const Sns = styled.div`
  position: absolute;
  height: 7.5rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
  ${media.lessThan.md`
    position: static;
    height: auto;
  `}
`;

const Mes = styled.div`
  text-align: center;
  color: white;
  line-height: 1.5rem;
  height: 1.5rem;
  margin-bottom: 2rem;
`;

const SnsItems = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 4rem;
  img {
    width: 2rem;
    margin-right: 0.8em;
  }

  ${media.lessThan.md`
    flex-flow: column;
    height: auto;
  `}
`;

const SnsItem = styled.a`
  display: block;
  border: none;
  width: 20%;
  color: white;
  background: ${props => props.type === "LINE" ? "#00c300" : props.type === "Twitter" ? "#00acee" : "#4dc247"};
  margin: 0 10px;
  font-size: 1rem;
  height: 4rem;
  line-height: 4rem;
  border-radius: 0.5rem;
  text-align: center;

  transition: 200ms;
  &:hover {
    opacity: 0.9;
  }
  ${media.lessThan.md`
    width: 60%;
    margin: 10px auto;
  `}
`;

const Footer = styled.footer`
  display: flex;
  background: black;
  position: relative;
  padding: 50px 100px;
  color: white;
  ${media.lessThan.md`
    flex-flow: column;
    padding: 40px;
  `}
`;

// const Links = styled.ul`
//   margin: 0;
//   padding: 0;
//   width: 50%;
//   list-style: none;
//   li {
//     margin: 20px 0;
//     cursor: pointer;
//     &:hover {
//       opacity: 0.8;
//     }
//   }
//   ${media.lessThan.md`
//     width: 100%;
//   `}
// `;

const Policy = styled.div`
  display: grid;
  align-items: end;
  margin-left: auto;
  width: 30%;
  font-size: 0.75rem;
  line-height: 3rem;
  img {
    width: 80px;
  }

  ${media.lessThan.md`
    width: 100%;
    margin-top: 40px 0;
    line-height: 1rem;
    img {
      margin: 20px 0;
    }
  `}
`;
