import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation_en from "./resources/en.js";
import translation_ja from "./resources/ja.js";
import translation_zhTW from "./resources/zh-TW.js";
import translation_zhCN from "./resources/zh-CN.js";

const resources = {
  ja: {
    translation: translation_ja
  },
  en: {
    translation: translation_en
  },
  "zh-TW": {
    translation: translation_zhTW
  },
  "zh-CN": {
    translation: translation_zhCN
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "ja", 
    interpolation: {
      escapeValue: false,
    }
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
  document.title = i18n.t('title');
});

export default i18n;
