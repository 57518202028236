import { css } from 'styled-components';
import mapValues from "lodash.mapvalues";

export const dimensionMap = {
  // sizes in react-bootstrap
  xs: [0, 575],
  sm: [576, 767],
  md: [768, 991],
  lg: [992, 1199],
  xl: [1200, 1399],
  xxl: [1400],
};
const sizes = Object.keys(dimensionMap).sort((a, b) => dimensionMap[a][0] - dimensionMap[b][0]);

export const media = {
  size: mapValues(dimensionMap, (value) => (strings, ...args) => css`
    @media (min-width: ${value[0]}px)${value ? ` and (max-width: ${value[1]}px)` : ""} {
      ${css(strings, ...args)}
    }
  `),
  lessThan: mapValues(dimensionMap, (value, key) => {
    const index = sizes.indexOf(key);

    const range = [0, index ? dimensionMap[sizes[index - 1]][1] : 0];
    return (strings, ...args) => css`
      @media (min-width: ${range[0]}px) and (max-width: ${range[1]}px) {
        ${css(strings, ...args)}
      }
    `;
  }),
};
