import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import Top from "./pages/Top";
import BusinessRealEstate from "./pages/BusinessRealEstate";
import BusinessCareerConsulting from "./pages/BusinessCareerConsulting";
import Recruit from "./pages/Recruit";
import CeoMessageMember from "./pages/CeoMessageMember";
import ForeignerLp1 from "./pages/ForeignerLp1";
import News from "./pages/News";
import Article from "./pages/Article";

import { Layout } from "./components/Layouts";
import ScrollTop from "./components/ScrollTop";
import Header from "./components/Header";
import Language from "./components/Language";

import { generateLocalizedPath, languages } from "./lib/languages";

import './styles/fonts.css';

const localizationRoutes = [{
  element: <Top />,
  path: "/",
  commonHeader: true,
}, {
  element: <BusinessRealEstate />,
  path: "/business/real-estate",
  commonHeader: true,
}, {
  element: <BusinessCareerConsulting />,
  path: "/business/career-consulting",
  commonHeader: true,
}, {
  element: <Recruit />,
  path: "/recruit",
  commonHeader: true,
}, {
  element: <CeoMessageMember />,
  path: "/ceo-message",
  commonHeader: true,
}, {
  element: <News />,
  path: "/news",
  commonHeader: true,
}, {
  element: <Article />,
  path: "/news/:id",
  commonHeader: true,
}];

const routes = [{
  element: <ForeignerLp1 />,
  path: "/foreigner/lp1",
  commonHeader: false,
}];

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }
  body {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  .black {
    color: black;
  }
  .red {
    color: red;
  }
  .bold {
    font-weight: bold;
  }

  .flex {
    display: flex;
  }
  .justify-center {
    justify-content: center;
  }
  .block {
    display: block;
  }
  .relative {
    position: relative;
  }

  .mt20 {
    margin-top: 20px;
  }
  .mt50 {
    margin-top: 50px;
  }
  .mt100 {
    margin-top: 100px;
  }
  .mb5 {
    margin-bottom: 5px;
  }
  .mb40 {
    margin-bottom: 40px;
  }
  .mb60 {
    margin-bottom: 60px;
  }
  .mv100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .mv20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mh20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mrauto {
    margin-right: auto;
  }
  .p10 {
    padding: 10px;
  }
  .pt20 {
    padding-top: 20px;
  }

  .text-center {
    text-align: center;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .br10 {
    border-radius: 10px;
  }
  .br10-r {
    border-radius: 0 10px 10px 0;
  }
  .pointer {
    cursor: pointer;
  }
  .ts200 {
    transition: 200ms;
  }
  .one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .two-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const theme = {
  page: {
    width: "1730px",
  },
  content: {
    width: "1280px",
    padding: "40px",
  },
  header: {
    height: "60px",
  },
};

const App = () => {
  useEffect(() => {
    TagManager.initialize({gtmId: "GTM-K3G9CBC"});
  }, []);

  return (
    <BrowserRouter>
      <ScrollTop />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Language>
          <Layout>
            <Routes>
              {languages.map((lang, i) => (
                localizationRoutes.map((route, j) => (
                  <Route
                    key={`${i}-${j}`}
                    path={generateLocalizedPath(route.path, lang)}
                    element={
                      <>
                        {route.commonHeader && <Header />}
                        {React.cloneElement(route.element, { lang })}
                      </>
                    }
                  />
                ))
              ))}
              {routes.map((route, i) => (
                <Route
                  key={i}
                  path={route.path}
                  element={
                    <>
                      {route.commonHeader && <Header />}
                      {route.element}
                    </>
                  }
                />
              ))}
              {languages.map((lang, i) => (
                <Route key={i} path={`/${lang}/*`} element={<Navigate to={generateLocalizedPath("/", lang)} />} />
              ))}
              <Route path="*" element={<Navigate to={generateLocalizedPath("/", "ja")} />} />
            </Routes>
          </Layout>
        </Language>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
