import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Business from "../components/top/Business";
import Staff from "../components/top/Staff";
import Slogan from "../components/top/Slogan";
import Foreigner from "../components/top/Foreigner";
import News from "../components/top/News";
import ContactForm from "../components/top/ContactForm";
import Company from "../components/top/Company";

import PageTopImage from "../assets/top/page-top.png"
import TopLogo from "../assets/logos/full.svg";
import Footer from "../assets/top/footer.png";

import { media } from "../lib/utils";

const Top = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTop>
        <div>
          <img src={TopLogo} alt={`NODOMARU / ${t("top.title")}`} />
          <h1>{t("top.title")}</h1>
        </div>
      </PageTop>
      <Business />
      <Staff />
      <Slogan />
      <Foreigner />
      <News />
      <ContactForm />
      <Company />
      <PageBottom />
    </>
  )
};

export default Top;

const PageTop = styled.div`
  position: relative;

  background: url(${PageTopImage});
  background-size: cover;
  background-position: center center;
  margin-bottom: 60px;

  width: 100%;
  height: 100vh;
  min-height: 600px;

  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 75%;
    height: fit-content;
    margin: auto;
    img {
      display: block;
      width: 100%;
    }
    h1 {
      color: white;
      font-size: 2.25rem;
      font-weight: 700;
      text-align: center;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      ${media.lessThan.md`
        font-size: 1.75rem;
      `}
      ${media.lessThan.sm`
        font-size: 1.25rem;
      `}
    }
  }
`;

const PageBottom = styled.div`
  background: url(${Footer});
  background-size: cover;
  background-position: center center;
  padding-bottom: 40%;
`;
