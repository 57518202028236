import styled from "styled-components";
import { useTranslation } from "react-i18next";

import TopImage from "../../assets/recruit/top.png";
import TopLogo from "../../assets/logos/full.svg";
import { media } from "../../lib/utils";

const PageTop = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Box>
        <img src={TopLogo} alt="NODOMARU" />
        <Title>{t("recruit.title")}</Title>
        <Subtitle>{t("recruit.subtitle")}</Subtitle>
      </Box>
    </Section>
  )
};
export default PageTop;

const Section = styled.section`
  background: url(${TopImage});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
  margin-bottom: 80px;
  position: relative;

  color: #FFF;

  ${media.lessThan.md`
    margin-bottom: 24px;
  `}
`;

const Box = styled.div`
  max-width: 450px;
  height: 160px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  right: 20px;
  margin: auto;
  img { width: 100%; }
`;

const Title = styled.h1`
  margin: 31px auto 0;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3.5rem;
  align-self: stretch;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

  border-top: 1px solid #FFF;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
`;
