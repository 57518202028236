import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";
import axios from "axios";
import { Col, Pagination, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { media } from "../../lib/utils";
import { generateLocalizedPath } from "../../lib/languages";
import i18n from "../../i18n/config";

const NewsItems = () => {
  const { t } = useTranslation();

  const lang = i18n.language;

  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const onClickCategory = (category) => {
    setCategory(category);
    setPage(1);
  };

  useEffect(() => {
    setLoading(true);
    setNews([]);
    (async () => {
      try {
        const res = await axios.get(`https://ndmr.co.jp/blog/?rest_route=/wp/v2/posts&_embed&per_page=6&page=${page}${category ? `&categories=${category}` : ""}`);
        setNews(res.data);
        setTotalPages(Number(res.headers["x-wp-totalpages"]));
        setLoading(false);
      } catch (error) {
        setNews([]);
      }
    })()
  }, [category, page]);

  return (
    <section>
      <Content>
        <h4>{t("news.categories")}</h4>
        <CateRow>
          <CateCol lg={3} md={6}>
            <Category onClick={() => onClickCategory("")} selected={category === ""}>All</Category>
          </CateCol>
          <CateCol lg={3} md={6}>
            <Category onClick={() => onClickCategory("6")} selected={category === "6"}>Events</Category>
          </CateCol>
          <CateCol lg={3} md={6}>
            <Category onClick={() => onClickCategory("7")} selected={category === "7"}>Report</Category>
          </CateCol>
          <CateCol lg={3} md={6}>
            <Category onClick={() => onClickCategory("10")} selected={category === "10"}>Blog</Category>
          </CateCol>
        </CateRow>
        {loading && <Spinner animation="border" />}
        <NewsRow>
          {!loading && news.map((item, i) => (
            <NewsCol key={i} lg={4} md={6}>
              <NewsItem key={i}>
                <StyledLink to={generateLocalizedPath(`/news/${item.id}`, lang)}>
                  <Image src={item["_embedded"]["wp:featuredmedia"]?.[0]["source_url"]} />
                  <h5 className="two-line">{item["title"]["rendered"]}</h5>
                  <p className="two-line">
                    {item["content"]["rendered"].replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'').replace(/&("[^"]*"|'[^']*'|[^'">])*;/g,'')}
                  </p>
                </StyledLink>
              </NewsItem>
            </NewsCol>
          ))}
        </NewsRow>
        {!loading && news.length === 0 && <p>No Posts</p>}
        <StyledPagination size="lg">
          <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
          <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1} />
          {Array.from({ length: totalPages }, (_, i) => {
            if (page - 3 > i + 1) return null;
            if (page - 3 === i + 1) return <Pagination.Ellipsis key={i} disabled />;
            if (page + 3 === i + 1) return <Pagination.Ellipsis key={i} disabled />;
            if (page + 3 < i + 1) return null;
            return (
              <Pagination.Item key={i} active={i + 1 === page} onClick={() => setPage(i + 1)}>{i + 1}</Pagination.Item>
            )
          })}
          <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === totalPages} />
          <Pagination.Last onClick={() => setPage(totalPages)} disabled={page === totalPages} />
        </StyledPagination>
        <MobilePagination size="sm">
          <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
          <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1} />
          {Array.from({ length: totalPages }, (_, i) => {
            if (page - 3 > i + 1) return null;
            if (page - 3 === i + 1) return <Pagination.Ellipsis key={i} disabled />;
            if (page + 3 === i + 1) return <Pagination.Ellipsis key={i} disabled />;
            if (page + 3 < i + 1) return null;
            return (
              <Pagination.Item key={i} active={i + 1 === page} onClick={() => setPage(i + 1)}>{i + 1}</Pagination.Item>
            )
          })}
          <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === totalPages} />
          <Pagination.Last onClick={() => setPage(totalPages)} disabled={page === totalPages} />
        </MobilePagination>
      </Content>
    </section>
  );
};
export default NewsItems;

const CateRow = styled(Row)`
  margin: -10px -10px 10px -10px;
`;

const CateCol = styled(Col)`
  padding: 10px;
`;

const Category = styled.button`
  width: 100%;
  background: transparent;
  color: ${props => props.selected ? "#7FC08B" : "rgba(5, 5, 5, 0.25)"};
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: 700;

  border-radius: 8px;
  border: 4px solid ${props => props.selected ? "#7FC08B" : "rgba(5, 5, 5, 0.25)"};
  padding: 0px 40px;
`;

const NewsRow = styled(Row)`
  margin: -10px -10px 10px -10px;
`;

const NewsCol = styled(Col)`
  padding: 10px;
`;

const NewsItem = styled.div`
  h5 {
    font-size: 1.5rem;
    line-height: 2rem;
    height: 4rem;
    margin-bottom: 12px;
  }
  p.two-line {
    font-size: 1rem;
    line-height: 1.5rem;
    height: 3rem;
    margin-bottom: 0;
  }
`;

const Image = styled.div`
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    transition: 200ms;
    width: 100%;
    padding-bottom: 75%;
    background: url(${props => props.src});
    background-size: cover;
    background-position: center center;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;

  transition: 200ms;
  &:hover {
    opacity: 0.7;
    ${Image} {
      &:after {
        transform: scale(1.1);
      }
    }
  }
`;

const StyledPagination = styled(Pagination)`
  display: flex;
  margin: auto;
  width: fit-content;
  margin-bottom: 100px;
  .page-item>a {
    color: #7FC08B !important;
  }
  ${media.lessThan.md`
    display: none;
  `}
`;

const MobilePagination = styled(StyledPagination)`
  display: none;
  ${media.lessThan.md`
    display: flex;
  `}
`;
