import styled from "styled-components";

import TopLogo from "../../assets/logos/full.svg";
import { media } from "../../lib/utils";

const PageTop = ({ topImage, mobileTop, title, subtitle }) => {
  return (
    <Section bgImg={topImage} mobileTop={mobileTop} >
      <img src={TopLogo} alt="NODOMARU" />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Section>
  )
};
export default PageTop;

const Section = styled.section`
  background: url(${props => props.bgImg});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 450px;
  margin-bottom: 60px;
  padding: 190px 20px 0;

  text-align: center;
  color: #FFF;
  img {
    width: 100%;
    max-width: 450px;
  }

  ${props => props.mobileTop && media.lessThan.md`
    background: url(${props.mobileTop});
    background-size: cover;
    background-position: center center;
  `}

`;

const Title = styled.h1`
  margin: 31px auto 0;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3.5rem;
  align-self: stretch;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

  max-width: 450px;
  border-top: 1px solid #FFF;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
`;
