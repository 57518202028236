import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Content } from "../Layouts";

import StaffImage from "../../assets/top/staff.png";
import { media } from "../../lib/utils";
import { generateLocalizedPath } from "../../lib/languages";
import i18n from "../../i18n/config";

const Staff = () => {
  const { t } = useTranslation();

  const lang = i18n.language;

  return (
    <section>
      <Content>
        <Boxes>
          <MobTitle>{t("top.staff.title")}</MobTitle>
          <MobSubtitle>{t("top.staff.subtitle")}</MobSubtitle>
          <MobDesc dangerouslySetInnerHTML={{ __html: t("top.staff.desc") }} />
          <Image image={StaffImage} />
          <TextWrapper>
            <Title>{t("top.staff.title")}</Title>
            <Subtitle>{t("top.staff.subtitle")}</Subtitle>
            <Desc dangerouslySetInnerHTML={{ __html: t("top.staff.desc") }} />
            <Link to="https://www.instagram.com/nodomaru2023/?igshid=YTQwZjQ0NmI0OA%3D%3D" target="_blank">
              <Button>{t("top.staff.links.instagram")}</Button>
            </Link>
            <Link to={generateLocalizedPath("/news", lang)}>
              <Button>{t("top.staff.links.blog")}</Button>
            </Link>
            <Link to="https://www.facebook.com/people/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENodomaru/61550832163498/?is_tour_completed=true" target="_blank">
              <Button>{t("top.staff.links.facebook")}</Button>
            </Link>
          </TextWrapper>
        </Boxes>
      </Content>
    </section>
  )
};
export default Staff;


const Boxes = styled.div`
  display: flex;
  margin-bottom: 60px;

  ${media.lessThan.lg`
    display: block;
  `}
`;

const MobTitle = styled.h2`
  display: none;
  text-align: center;

  ${media.lessThan.lg`
    display: block;
    font-size: 2.25rem;
    font-weight: 700;
    margin: 0;
  `}
`;

const MobSubtitle = styled.p`
  display: none;
  text-align: center;

  ${media.lessThan.lg`
    display: block;
    font-size: 2.25rem;
    margin: 0;
  `}
`;

const MobDesc = styled.p`
  display: none;

  ${media.lessThan.lg`
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 20px;
  `}
`;

const Image = styled.div`
  width: 50%;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 50%;

  background: url(${props => props.image});
  background-size: cover;
  background-position: center center;

  ${media.lessThan.lg`
    width: 100%;
    padding-bottom: 300px;
  `}
  ${media.lessThan.sm`
    padding-bottom: 70%;
  `}
`;

const TextWrapper = styled.div`
  width: 50%;
  padding-left: 80px;

  ${media.lessThan.lg`
    width: 100%;
    padding-left: 0;
  `}
`;

const Title = styled.h2`
  font-size: 3rem;
  margin: 0;

  ${media.lessThan.lg`
    display: none;
  `}
`;

const Subtitle = styled.p`
  font-size: 2.5rem;
  margin: 0;

  ${media.lessThan.lg`
    display: none;
  `}
`;

const Desc = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 20px;

  ${media.lessThan.lg`
    display: none;
  `}
`;

const Button = styled.button`
  width: 100%;
  margin-top: 24px;
  padding: 0;
  border-radius: 8px;
  border: 4px solid #000;

  background: #fff;
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 4.5rem;
`;
